<template>
  <!--  loading 动态-->
  <div v-if="isLoading" :style="getStyleByBodyWd(0,1)" class="flex_row_center" ><img :src="imgs.loading"  :style="getStyleByBodyWd(0,1.8)" ></div>
  <!--  主页-->
  <div  v-else  style="  background: rgba(255, 255, 255, 1);"     class=" flex_row_center"  :style="getStyleByBodyWd(1,1)">
    <div  style="  background: rgba(255, 255, 255, 1);"     class=" flex_row_center"  :style="getStyleByBodyWd(1,1)">
      <div  :style="getStyleByBodyWd(15.7,1)" :class="asideClass[catalogSelect]" class="flex_row_center">
        <div  :style="getStyleByBodyWd(15.7,1.15)"  style="display: flex;flex-direction: column;justify-content: space-between;">
          <div style="display: flex;justify-content: center;align-items: start">
            <div class="text_vertical" :style="setFontSizeByBodyH(38.5) +'margin-right:'+htmlHeight/200+'px;'"> {{models[catalogSelect].title}}</div>
            <div class="text_vertical" :style="setFontSizeByBodyH(38.5)">{{models[catalogSelect].title_en }}</div>
          </div>
          <div>
            <div  @click="rebackCatalogPage" style="cursor: pointer; " >
              <img :src="imgs.classIcon" :style="getStyleByBodyWd(0,22.5) +'margin-bottom:'+htmlHeight/100+'px;'"  >
              <div :style="setFontSizeByBodyH(67.5)  +'margin-bottom:'+htmlHeight/300+'px;'">类别选择</div>
              <div :style="setFontSizeByBodyH(67.5)">Departments</div>
            </div>
            <div :style="'margin-top:'+htmlHeight/10.8+'px;'" style="cursor: pointer; " @click="refreashBtn">
              <img :src="imgs.refresh" :style="getStyleByBodyWd(0,22.5) +'margin-bottom:'+htmlHeight/100+'px;'" >
              <div :style="setFontSizeByBodyH(67.5) +'margin-bottom:'+htmlHeight/300+'px;'">刷新页面</div>
              <div :style="setFontSizeByBodyH(67.5)">Refresh</div>
            </div>
          </div>
        </div>
      </div>
      <div  :style="getStyleByBodyWd(1.067,1)" class="flex_row_center ">
        <div  :style="getStyleByBodyWd(1.11,1.08)"  style="display: flex;justify-content: center; "  >
          <el-scrollbar   :style="getStyleByBodyWd(1.11,1.05) +'margin-left:'+this.htmlWidth/150+'px;' " style="display: flex;justify-content: center;  color: black"   >
            <div   class="v-waterfall-content    " id="v-waterfall"   :style="getStyleByBodyWd(1.11,1.06) "    >
              <div v-for="(img,index) in this.waterfallList" :key="index" class="v-waterfall-item     "   :style="{top:getWaterfall_top(img.id)+'px',left:getWaterfall_left(img.id)+'px',width:waterfallImgWidth+'px',height:img.height}" >
                <div class="case_card   " style="width: 90%;height: 90%; cursor: pointer; "   @click=" openCaseDetail(img.id)"  :style="' border-radius:'+htmlHeight/80+'px'"  >
                  <div   style="display: flex;justify-content: center;" >
                  </div>
                  <img    v-lazy="img.src"  style="width: 100%;height: 100%;"  />
                  <div v-if="catalogSelect==5"   :style="getStyleByBodyWd(0,8.9)" style="display: flex;flex-direction: column;justify-content: space-around;align-items: start;" >
                    <p     :style="getStyleByBodyWd(0,26) +setFontSizeByBodyH(49)" class="font_family_sy_balck" >{{img.title}}   </p>
                    <p    :style="setFontSizeByBodyH(60)"   style="overflow: hidden">{{overHiddenText(caseDetails[catalogSelect][img.id].text,12)}} </p>
                  </div>
                  <div v-else style="display: flex;justify-content: center;align-items: center;"  >
                    <p    :style="getStyleByBodyWd(0,26) +setFontSizeByBodyH(38.5)">   {{overHiddenText(img.title,13)}}</p>
                  </div>

                </div>

              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

// import CaseDetail from "@/pages/caseDetail";
export default {
  name: "waterFall",
  // components: {CaseDetail},
  data(){
    return{

      asideClass:['aside-img0 ','aside-bg1 ','aside-bg2 ','aside-bg3 ','aside-bg4 ',],
      models:[
        {
          title:"家电家居",
          title_en:"Household Appliances",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow1.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line1.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_1.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_1.webp',
        },
        {
          title:"3C数码",
          title_en:"3C Digital Accessories",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow2.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line2.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_2.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_2.webp',
        },
        {
          title:"童品优选",
          title_en:"Children's Products",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow3.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line3.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_3.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_3.webp',
        },
        {
          title:"个护健康",
          title_en:"Personal Healthcare",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow4.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line3.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_4.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_4.webp',
        },
        {
          title:"两季产品",
          title_en:"Seasonal Products",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow5.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line4.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_5.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_5.webp',
        },
        {
          title:"品牌全案设计",
          title_en:"Brand Design",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow6.webp',
          line:"",
          done:"",
          detail_line: "",
        },
      ],
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      imgs:{
        button:this.$mediaURL+'/ESER_guide/page0/button_r.gif',
        background:this.$mediaURL+'/ESER_guide/team/team.webp',
        icon:"",
        loading:this.$mediaURL+'/ESER_guide/loading.gif',
        classIcon:this.$mediaURL+'/ESER_guide/classIcon.svg',
        refresh:this.$mediaURL+'/ESER_guide/refresh.svg',
      },
      case_detail:-1,
      catalogSelect:0,
      isLoading:false,//加载动画标志位
      //waterfall
      waterfallImgCol: 4,// 瀑布流的列数
      waterfallImgWidth: 0,// 每个盒子的宽度
      waterfallImgRight: 0,// 每个盒子的右padding
      waterfallImgBottom:0,// 每个盒子的下padding
      waterfallDeviationHeight: [],
      imgList: [],
      waterfallList: [],
      waterfall_model:[
        {
          src:this.$mediaURL+'/ESER_guide/case1/01.webp',
          width:0,
          height:0,
        },
        {
          src:this.$mediaURL+'/ESER_guide/case1/02.webp',
          width:0,
          height:0,
        },

      ],
      caseDetails:[
          [
          {
            id:0,
            title:"华为智选乐骑智能电动滑板车",
            cover:this.$mediaURL+'/ESER_guide/case1/01.webp',
            type:"外观设计",
            info:'整体车身设计灵感源于奔腾的马背，以流畅的弓字造型打造棱角分明的腰线，赋予车身力量感、耐用和速度感。\n' +
                '采用汽车级一体化工艺，坚固的铝合金保护壳，IPX7级防水电池。\n' +
                '近距离靠近即开启灯效，智能夜行灯搭配两侧LED灯珠构成的氛围灯。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/01/3.webp',
              this.$mediaURL+'/ESER_guide/case1/01/2.webp',
              this.$mediaURL+'/ESER_guide/case1/01/1.webp',
              this.$mediaURL+'/ESER_guide/case1/01/4.webp',
            ],
          },
          {
            id:1,
            title:"Tribit户外音箱",
            cover:this.$mediaURL+'/ESER_guide/case1/02.webp',
            type:"外观设计",
            info:'顶部二次圆弧角的方形设计，与整体造型相得益彰。方圆之间，尽显流畅饱满。立体“水壶式”设计，音箱四周IPX7深度级别防水网布面罩，在保证透气利于声音传播的同时，顽强抵御水的入侵，由内而外透露出安全稳重感。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/02/1.webp',
              this.$mediaURL+'/ESER_guide/case1/02/2.webp',
              this.$mediaURL+'/ESER_guide/case1/02/3.webp',
              this.$mediaURL+'/ESER_guide/case1/02/4.webp',
              this.$mediaURL+'/ESER_guide/case1/02/5.webp',
              this.$mediaURL+'/ESER_guide/case1/02/6.webp',
            ],
          },
          {
            id:2,
            title:"户外蓝牙音箱",
            cover:this.$mediaURL+'/ESER_guide/case1/03.webp',
            type:"外观设计",
            info:'大面积低亮度、暗黑基调的主体设计，正面高饱和度的霓虹灯点缀。融合酷炫的科技风与变幻莫测的光影，碰撞出奇异的美感。\n' +
                '音箱主体采用柔和的线条设计，采用轻量化调整把手，减轻体块感，增加孔洞作为吊扣孔。正面采用铁网设计做包裹，侧面灯光贴合铁网造型，更具统一感。\n' +
                '音箱造型线条取自豪车侧面线条，流畅舒适；两侧灯光采用与车灯类似的三角造型，塑造更强的动感，让用户更直观地感受到音乐的律动。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/03/1.webp',
              this.$mediaURL+'/ESER_guide/case1/03/2.webp',
              this.$mediaURL+'/ESER_guide/case1/03/3.webp',
              this.$mediaURL+'/ESER_guide/case1/03/4.webp',
              this.$mediaURL+'/ESER_guide/case1/03/5.webp',
            ],
          },
          {
            id:3,
            title:"倍特力储能电源",
            cover:this.$mediaURL+'/ESER_guide/case1/04.webp',
            type:"外观设计",
            info:'以“新能源、新风貌”为设计理念。摒弃传统电源的工程设备感，追求简约、精致、品质感的现代主流审美。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/04/1.webp',
              this.$mediaURL+'/ESER_guide/case1/04/2.webp',
              this.$mediaURL+'/ESER_guide/case1/04/3.webp',
              this.$mediaURL+'/ESER_guide/case1/04/4.webp',
              this.$mediaURL+'/ESER_guide/case1/04/5.webp',
              this.$mediaURL+'/ESER_guide/case1/04/6.webp',
            ],
          },
          {
            id:4,
            title:"地毯清洁机",
            cover:this.$mediaURL+'/ESER_guide/case1/05.webp',
            type:"外观设计",
            info:'设计强调现代感和硬朗感，采用简洁的线条和坚固的外形，创造出适应现代家居的外观。外观融入了科技感和机械感的元素，赋予产品未来感，提升用户清洁的科技体验。\n' +
                '采用先进的清洁技术，能够深入地毯纤维，高效清除污渍和灰尘。设备内置多重安全保护机制，确保在使用过程中的安全。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/05/1.webp',
              this.$mediaURL+'/ESER_guide/case1/05/2.webp',
              this.$mediaURL+'/ESER_guide/case1/05/3.webp',
            ],
          },
          {
            id:5,
            title:"倍特力户外电源",
            cover:this.$mediaURL+'/ESER_guide/case1/06.webp',
            type:"外观设计",
            info:'参考西装马甲笔挺的外轮廓，打造整体外观线条，顶面设计“银耳”造型提手，灵感取自奥迪RS车型的别称“奥迪银耳”，凸显其高性能特点，商务范十足。\n' +
                '休闲和商务两不误，顶面及侧面结合拆件方式采用大面积流瀑式散热条设计，由手风琴的“风箱”造型演变而来，更赋予其灵动轻巧之美。\n' +
                '左侧散热孔阵完美融入格栅之中，右侧与底部LED灯通过格栅的连接打造圆形特征，直曲相宜。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/06/1.webp',
              this.$mediaURL+'/ESER_guide/case1/06/2.webp',
              this.$mediaURL+'/ESER_guide/case1/06/3.webp',
              this.$mediaURL+'/ESER_guide/case1/06/4.webp',
            ],
          },
          {
            id:6,
            title:"魔方会议音箱",
            cover:this.$mediaURL+'/ESER_guide/case1/07.webp',
            type:"外观设计",
            info:'外观灵感来源于魔方，呈现出立体感和立方体的几何美。整体呈方形形态，稳重而不失现代感。在方形的一角切出小方形，为产品增加独特的设计特色，同时也是操作按键的区域。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/07/1.webp',
              this.$mediaURL+'/ESER_guide/case1/07/2.webp',
              this.$mediaURL+'/ESER_guide/case1/07/3.webp',
              this.$mediaURL+'/ESER_guide/case1/07/4.webp',
              this.$mediaURL+'/ESER_guide/case1/07/5.webp',
              this.$mediaURL+'/ESER_guide/case1/07/6.webp',
            ],
          },
          {
            id:7,
            title:"立式投影仪",
            cover:this.$mediaURL+'/ESER_guide/case1/08.webp',
            type:"外观设计",
            info:'整体设计取自都市中繁华的高楼大厦，笔直沉稳的轮廓线条，酷似大楼外墙体长条形线条灯，既起到散热作用，又具备装饰效果。\n' +
                '投影仪的镜头及周围部件，灵感汲取自幕墙透明LED显示屏，只为匠心呈现一场充满梦幻色彩的灯光秀。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/08/1.webp',
              this.$mediaURL+'/ESER_guide/case1/08/2.webp',
              this.$mediaURL+'/ESER_guide/case1/08/3.webp',
              this.$mediaURL+'/ESER_guide/case1/08/4.webp',

            ],
          },
          {
            id:8,
            title:"干果机",
            cover:this.$mediaURL+'/ESER_guide/case1/09.webp',
            type:"外观设计",
            info:'以硬朗和现代感为核心，融入透明视窗，让用户随时观察干果烘烤的过程，增加互动性和使用乐趣。使用不锈钢材质，提升产品的耐用性和坚固感，同时赋予外观高贵光泽。旨在提供用户卓越的使用体验，同时赋予产品坚固性和时尚感，将现代感和工业风融入外观设计。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/09/1.webp',
              this.$mediaURL+'/ESER_guide/case1/09/2.webp',
              this.$mediaURL+'/ESER_guide/case1/09/3.webp',
            ],
          },
          {
            id:9,
            title:"烤箱",
            cover:this.$mediaURL+'/ESER_guide/case1/10.webp',
            type:"外观设计",
            info:'设计强调烤箱的专业感和品质感，通过简洁的线条和高质感的材料，创造出专业厨房的氛围。\n' +
                '采用隐藏式把手设计，使外观更加整洁，同时减少了碰撞风险，提升了安全性。\n' +
                '宽敞的烤箱腔体，满足各种烹饪需求。设备操作界面简单直观，用户轻松选择不同的烹饪模式和温度，实现精准烹饪。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/10/1.webp',
              this.$mediaURL+'/ESER_guide/case1/10/2.webp',
              this.$mediaURL+'/ESER_guide/case1/10/3.webp',
              this.$mediaURL+'/ESER_guide/case1/10/4.webp',

            ],
          },
          {
            id:10,
            title:"空气炸锅",
            cover:this.$mediaURL+'/ESER_guide/case1/11.webp',
            type:"外观设计",
            info:'设计主调以硬朗的线条和极简的造型为特点，以硬朗和极简的欧美风格为基础，融入金属质感，为用户带来直观、畅快的烹饪体验。\n' +
                '外观以金属质感为基础，让产品看起来更具高贵品质，也增添了烹饪的仪式感。\n' +
                '超大容量设计，满足多人用餐或家庭聚会的需要，设备配备直观的操作界面，让用户轻松掌握烹饪过程，即便是新手也能轻松上手。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/11/1.webp',
              this.$mediaURL+'/ESER_guide/case1/11/2.webp',
              this.$mediaURL+'/ESER_guide/case1/11/3.webp',
              this.$mediaURL+'/ESER_guide/case1/11/4.webp',

            ],
          },
          {
            id:11,
            title:"绞肉机",
            cover:this.$mediaURL+'/ESER_guide/case1/12.webp',
            type:"外观设计",
            info:'设计采用方圆弧的造型特征，既保留了柔和的外观，又凸显了现代感，使产品融入各种厨房环境。\n' +
                '设备在中部进行轻微的收腰设计，不仅增加了视觉的流畅感，也更符合人体工程学，提升了握持的舒适性。\n' +
                '设计注重用户体验，确保操作简便、清洁方便，为用户提供顺畅的使用感受。\n' +
                '在握持部分采用硅胶材质，增加握持的舒适性和防滑性，让用户的使用更加安心。\n' +
                '关键部件选用不锈钢材质，保证产品的耐用性和卫生性，适应多种食材的处理。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/12/1.webp',
              this.$mediaURL+'/ESER_guide/case1/12/2.webp',

            ],
          },
          {
            id:12,
            title:"陶瓷炖锅",
            cover:this.$mediaURL+'/ESER_guide/case1/13.webp',
            type:"外观设计",
            info:'采用时尚的配色方案，设计了两个独立的炖位，可同时烹饪多种食材，满足不同口味的需求。\n' +
                '设备配备多种烹饪模式，如慢炖、煮粥、炖汤等，适应不同食材和口味的烹饪需求。\n' +
                '操作界面简单直观，让用户轻松掌握烹饪流程，即使是新手也能轻松上手。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/13/1.webp',
              this.$mediaURL+'/ESER_guide/case1/13/2.webp',
              this.$mediaURL+'/ESER_guide/case1/13/3.webp',
              this.$mediaURL+'/ESER_guide/case1/13/4.webp',

            ],
          },
          {
            id:13,
            title:"便携式电饭锅",
            cover:this.$mediaURL+'/ESER_guide/case1/14.webp',
            type:"外观设计",
            info:'设计以圆润的外观、桶状的形态、家居风格为基础，结合单边把手和大圆形显示区域，为用户打造便捷、美观的烹饪体验。顶部设计宽大的圆形显示区域，能够清晰地查看烹饪状态，操作更加方便。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/14/1.webp',
              this.$mediaURL+'/ESER_guide/case1/14/2.webp',
              this.$mediaURL+'/ESER_guide/case1/14/3.webp',
              this.$mediaURL+'/ESER_guide/case1/14/4.webp',
              this.$mediaURL+'/ESER_guide/case1/14/5.webp',
              this.$mediaURL+'/ESER_guide/case1/14/6.webp',
            ],
          },
          {
            id:14,
            title:"空气净化器",
            cover:this.$mediaURL+'/ESER_guide/case1/15.webp',
            type:"外观设计",
            info:'设计采用极简现代风格，注重线条的简洁和形状的几何感，使产品与现代家居融合。外观呈方形，使其更易于放置在各种家居空间中，符合现代家居的审美。\n' +
                '内置高效净化系统，能够有效去除室内的细颗粒物和污染物，提供清新的室内空气。设备配备智能控制系统，可根据室内空气质量自动调整工作模式，提供更加智能化的使用体验',
            main:[
              this.$mediaURL+'/ESER_guide/case1/15/1.webp',
              this.$mediaURL+'/ESER_guide/case1/15/2.webp',
              this.$mediaURL+'/ESER_guide/case1/15/3.webp',
              this.$mediaURL+'/ESER_guide/case1/15/4.webp',
              this.$mediaURL+'/ESER_guide/case1/15/5.webp',

            ],
          },
          {
            id:15,
            title:"车载净化器",
            cover:this.$mediaURL+'/ESER_guide/case1/16.webp',
            type:"外观设计",
            info:'设计融合了碳纤维、现代、简洁的特点，结合UV灯消毒和臭氧消毒技术，为车内带来清新、健康的空气。通过高科技的设计和先进的技术，提升驾驶者的驾车体验，创造一个舒适、洁净的车内环境。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/16/1.webp',
              this.$mediaURL+'/ESER_guide/case1/16/2.webp',
              this.$mediaURL+'/ESER_guide/case1/16/3.webp',
              this.$mediaURL+'/ESER_guide/case1/16/4.webp',
              this.$mediaURL+'/ESER_guide/case1/16/5.webp',

            ],
          },
          {
            id:16,
            title:"手持吸尘器",
            cover:this.$mediaURL+'/ESER_guide/case1/17.webp',
            type:"外观设计",
            info:'充满科技感和线条感的外观设计，在完整性较高的主体上进行线条刻画，同时与轮廓线相关联，使产品具有向前的动势和力量感，手柄处采用人体工学设计，平整圆润的曲面保证操作的舒适性。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/17/1.webp',
              this.$mediaURL+'/ESER_guide/case1/17/2.webp',
              this.$mediaURL+'/ESER_guide/case1/17/3.webp',
              this.$mediaURL+'/ESER_guide/case1/17/4.webp',
              this.$mediaURL+'/ESER_guide/case1/17/5.webp',

            ],
          },
          {
            id:17,
            title:"挂烫机",
            cover:this.$mediaURL+'/ESER_guide/case1/18.webp',
            type:"外观设计",
            info:'',
            main:[
              this.$mediaURL+'/ESER_guide/case1/18/1.webp',
              this.$mediaURL+'/ESER_guide/case1/18/2.webp',
              this.$mediaURL+'/ESER_guide/case1/18/3.webp',
              this.$mediaURL+'/ESER_guide/case1/18/4.webp',

            ],
          },
          {
            id:18,
            title:"除湿机",
            cover:this.$mediaURL+'/ESER_guide/case1/19.webp',
            type:"外观设计",
            info:'突破性的造型设计，通过几何形体的穿插叠合，头部融合显示屏幕，中间部分设计为水箱，在顶壳抽出后可以做为手柄进行竖向使用。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/19/1.webp',
              this.$mediaURL+'/ESER_guide/case1/19/2.webp',
              this.$mediaURL+'/ESER_guide/case1/19/3.webp',
              this.$mediaURL+'/ESER_guide/case1/19/4.webp',
              this.$mediaURL+'/ESER_guide/case1/19/5.webp',

            ],
          },
          {
            id:19,
            title:"电火锅",
            cover:this.$mediaURL+'/ESER_guide/case1/20.webp',
            type:"外观设计",
            info:'外形为独特的锥桶状，稳定性高，外观采用金属材质，赋予了产品高级的外观质感，与现代家居搭配无缝融合。顶部特别设计了环绕式的圆形灯圈，增添装饰性，同时也能提供柔和照明，营造舒适氛围。\n' +
                '\n' +
                '正面设有透明视窗，方便用户随时查看湿度指示和工作状态，增加了用户与产品的互动性。内部装备先进的除湿技术，迅速有效地降低室内湿度，为居住环境创造舒适条件。顶部的环形灯圈不仅可用于提供照明，还可以根据用户需求调整颜色和亮度，为空间增添多样性。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/20/1.webp',
              this.$mediaURL+'/ESER_guide/case1/20/2.webp',
              this.$mediaURL+'/ESER_guide/case1/20/3.webp',
              this.$mediaURL+'/ESER_guide/case1/20/4.webp',
              this.$mediaURL+'/ESER_guide/case1/20/5.webp',

            ],
          },
          {
            id:20,
            title:"置物架系列化",
            cover:this.$mediaURL+'/ESER_guide/case1/21.webp',
            type:"外观设计",
            info:'这是一款专为火锅爱好者设计的一体式电煮锅，设计灵感来源于宫廷器皿，外观饱满圆润。在操控方式上脱离传统的旋钮，采用拨动的方式，给予用户新奇体验。细节上锅身把手和顶部把手纹理互相呼应，指示界面采用了复古钟表样式，搭配金色喷漆材质。\n' +
                '细节处以“花”为灵感来源，结合古典家具的高脚设计，呈现复古轻奢的设计理念。\n' +
                '多处花朵元素的出现，花瓣元素运用到了按键处，花的四瓣指向电煮锅的四个功能。升级款将花的元素运用到了侧面和顶部把手上。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/21/1.webp',
              this.$mediaURL+'/ESER_guide/case1/21/2.webp',
              this.$mediaURL+'/ESER_guide/case1/21/3.webp',
              this.$mediaURL+'/ESER_guide/case1/21/4.webp',
              this.$mediaURL+'/ESER_guide/case1/21/5.webp',
              this.$mediaURL+'/ESER_guide/case1/21/6.webp',
              this.$mediaURL+'/ESER_guide/case1/21/7.webp',
              this.$mediaURL+'/ESER_guide/case1/21/8.webp',
              this.$mediaURL+'/ESER_guide/case1/21/9.webp',
              this.$mediaURL+'/ESER_guide/case1/21/10.webp',
              this.$mediaURL+'/ESER_guide/case1/21/11.webp',
              this.$mediaURL+'/ESER_guide/case1/21/12.webp',
            ],
          },
          {
            id:21,
            title:"叠加式水壶",
            cover:this.$mediaURL+'/ESER_guide/case1/22.webp',
            type:"外观设计",
            info:'置物架整体呈现圆润简洁的外观，跑道圆和圆形元素巧妙融合，使设计更具层次感和创意。\n' +
                '\n' +
                '推出多种尺寸、颜色和风格的置物架，满足不同用户的需求。系列中的每个置物架都保持统一的设计语言，增强整体协调性。适用于不同房间和用途，如客厅、卧室、书房等，为家居提供更多收纳解决方案',
            main:[
              this.$mediaURL+'/ESER_guide/case1/22/1.webp',
              this.$mediaURL+'/ESER_guide/case1/22/2.webp',
              this.$mediaURL+'/ESER_guide/case1/22/3.webp',
              this.$mediaURL+'/ESER_guide/case1/22/4.webp',

            ],
          },
        ],
          [
            {
              id:0,
              title:"努比亚方糖快速充电器",
              cover:this.$mediaURL+'/ESER_guide/case2/01.webp',
              type:"外观设计",
              info:'糖果般大小随身便携，表面亮面处理如同透明外壳，既轻巧又优雅。 清新色彩搭配，无疑是充电器中的颜值担当。',
            },
            {
              id:1,
              title:"澳德鸿二合一可折叠无线充",
              cover:this.$mediaURL+'/ESER_guide/case2/02.webp',
            },
            {
              id:2,
              title:"桌面充电器",
              cover:this.$mediaURL+'/ESER_guide/case2/03.webp',
              type:"",
            },
            {
              id:3,
              title:"今翔磁吸充电宝",
              cover:this.$mediaURL+'/ESER_guide/case2/04.webp',
              type:"",
            },
            {
              id:4,
              title:"今翔无线充排插",
              cover:this.$mediaURL+'/ESER_guide/case2/05.webp',
              type:"",
            },
            {
              id:5,
              title:"今翔三合一快充",
              cover:this.$mediaURL+'/ESER_guide/case2/06.webp',
              type:"",
            },
            {
              id:6,
              title:"今翔150w商务移动电源",
              cover:this.$mediaURL+'/ESER_guide/case2/07.webp',
              type:"",
            },
            {
              id:7,
              title:"会议音频",
              cover:this.$mediaURL+'/ESER_guide/case2/08.webp',
              type:"",
            },
            {
              id:8,
              title:"互拓无线麦克风",
              cover:this.$mediaURL+'/ESER_guide/case2/09.webp',
              type:"",
            },
            {
              id:9,
              title:"电吹管",
              cover:this.$mediaURL+'/ESER_guide/case2/10.webp',
              type:"",
            },
            {
              id:10,
              title:"复古收音机",
              cover:this.$mediaURL+'/ESER_guide/case2/11.webp',
              type:"",
            },
            {
              id:11,
              title:"猫耳头戴式耳机",
              cover:this.$mediaURL+'/ESER_guide/case2/12.webp',
              type:"",
            },
            {
              id:12,
              title:"安全摄像头",
              cover:this.$mediaURL+'/ESER_guide/case2/13.webp',
              type:"",
            },
            {
              id:13,
              title:"直播灯",
              cover:this.$mediaURL+'/ESER_guide/case2/14.webp',
              type:"",
            },
            {
              id:14,
              title:"组创微扫描仪",
              cover:this.$mediaURL+'/ESER_guide/case2/15.webp',
              type:"",
            },
            {
              id:15,
              title:"中云景星智能哑铃",
              cover:this.$mediaURL+'/ESER_guide/case2/16.webp',
              type:"",
            },
            {
              id:16,
              title:"绿巨能HDMI高清链接线",
              cover:this.$mediaURL+'/ESER_guide/case2/17.webp',
              type:"",
            },
            {
              id:17,
              title:"数据线",
              cover:this.$mediaURL+'/ESER_guide/case2/18.webp',
              type:"",
            },
          ],
          [
            {
              id:0,
              title:"再玩儿童洗牙器",
              cover:this.$mediaURL+'/ESER_guide/case3/01.webp',
            },
            {
              id:1,
              title:"儿童水杯",
              cover:this.$mediaURL+'/ESER_guide/case3/02.webp',
            },
            {
              id:2,
              title:"学伴互联电子摇铃",
              cover:this.$mediaURL+'/ESER_guide/case3/03.webp',
            },
            {
              id:3,
              title:"轮马克智能语音款儿童牙刷",
              cover:this.$mediaURL+'/ESER_guide/case3/04.webp',
            },
            {
              id:4,
              title:"墨趣儿童音箱",
              cover:this.$mediaURL+'/ESER_guide/case3/05.webp',
            },
            {
              id:5,
              title:"儿童手拍鼓",
              cover:this.$mediaURL+'/ESER_guide/case3/06.webp',
            },
            {
              id:6,
              title:"儿童K歌麦克风",
              cover:this.$mediaURL+'/ESER_guide/case3/07.webp',
            },
            {
              id:7,
              title:"点读笔",
              cover:this.$mediaURL+'/ESER_guide/case3/08.webp',
            },
            {
              id:8,
              title:"德兰达儿童磨甲器",
              cover:this.$mediaURL+'/ESER_guide/case3/09.webp',
            },
            {
              id:9,
              title:"K歌麦",
              cover:this.$mediaURL+'/ESER_guide/case3/10.webp',
            },
            {
              id:10,
              title:"儿童相机",
              cover:this.$mediaURL+'/ESER_guide/case3/11.webp',
            },
            {
              id:11,
              title:"儿童闹钟",
              cover:this.$mediaURL+'/ESER_guide/case3/12.webp',
            },
            {
              id:12,
              title:"雾化器",
              cover:this.$mediaURL+'/ESER_guide/case3/13.webp',
            },
            {
              id:13,
              title:"学习桌",
              cover:this.$mediaURL+'/ESER_guide/case3/14.webp',
            },
            {
              id:14,
              title:"游戏机",
              cover:this.$mediaURL+'/ESER_guide/case3/15.webp',
            },

          ],
          [
          {
            id:0,
            title:"射频美容仪",
            cover:this.$mediaURL+'/ESER_guide/case4/01.webp',
            type:"外观设计",
            info:'',
          },
          {
            id:1,
            title:"轮马克可视化黑头仪",
            cover:this.$mediaURL+'/ESER_guide/case4/02.webp',
            type:"",
          },
          {
            id:2,
            title:"脱毛仪",
            cover:this.$mediaURL+'/ESER_guide/case4/03.webp',
            type:"",
          },
          {
            id:3,
            title:"大穗直发器",
            cover:this.$mediaURL+'/ESER_guide/case4/04.webp',
            type:"",
          },
          {
            id:4,
            title:"轮马克吹风梳",
            cover:this.$mediaURL+'/ESER_guide/case4/05.webp',
            type:"",
          },
          {
            id:5,
            title:"亚细亚卷发棒",
            cover:this.$mediaURL+'/ESER_guide/case4/06.webp',
            type:"",
          },
          {
            id:6,
            title:"头部按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/07.webp',
            type:"",
          },
          {
            id:7,
            title:"吊坠按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/08.webp',
            type:"",
          },
          {
            id:8,
            title:"膝盖按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/09.webp',
            type:"",
          },
          {
            id:9,
            title:"四叶草充电式颈部按摩枕",
            cover:this.$mediaURL+'/ESER_guide/case4/10.webp',
            type:"",
          },
          {
            id:10,
            title:"智能眼部按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/11.webp',
            type:"",
          },
          {
            id:11,
            title:"腰部按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/12.webp',
            type:"",
          },
          {
            id:12,
            title:"恩博坐灸仪",
            cover:this.$mediaURL+'/ESER_guide/case4/13.webp',
            type:"",
          },
          {
            id:13,
            title:"筋膜枪",
            cover:this.$mediaURL+'/ESER_guide/case4/14.webp',
            type:"",
          },
          {
            id:14,
            title:"传奇美微针导入仪",
            cover:this.$mediaURL+'/ESER_guide/case4/15.webp',
            type:"",
          },
          {
            id:15,
            title:"轮马克纳米注氧仪",
            cover:this.$mediaURL+'/ESER_guide/case4/16.webp',
            type:"",
          },
          {
            id:16,
            title:"眼雾仪",
            cover:this.$mediaURL+'/ESER_guide/case4/17.webp',
            type:"",
          },
          {
            id:17,
            title:"再玩电动牙刷",
            cover:this.$mediaURL+'/ESER_guide/case4/18.webp',
            type:"",
          },
          {
            id:18,
            title:"晨欣达壁挂式智能牙刷消毒架",
            cover:this.$mediaURL+'/ESER_guide/case4/19.webp',
            type:"",
          },
          {
            id:19,
            title:"井井有序血压仪",
            cover:this.$mediaURL+'/ESER_guide/case4/20.webp',
            type:"",
          },
          {
            id:20,
            title:"眼镜清洗盒",
            cover:this.$mediaURL+'/ESER_guide/case4/21.webp',
            type:"",
          },
          {
            id:21,
            title:"固特小型家用超声波清洗机",
            cover:this.$mediaURL+'/ESER_guide/case4/22.webp',
            type:"",
          },
            {
              id:22,
              title:"家瑞康压缩式雾化器",
              cover:this.$mediaURL+'/ESER_guide/case4/23.webp',
              type:"",
            },
        ],
          [
          {
            id:0,
            title:"挂脖风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/01.webp',
            type:"外观设计",
            info:'',
          },
          {
            id:1,
            title:"桌面喷雾风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/02.webp',
            type:"",
          },
          {
            id:2,
            title:"再玩婴儿车风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/03.webp',
            type:"",
          },
          {
            id:3,
            title:"维特世嘉折叠小风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/04.webp',
            type:"",
          },
          {
            id:4,
            title:"再玩户外风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/05.webp',
            type:"",
          },
          {
            id:5,
            title:"再玩折叠壁挂式风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/06.webp',
            type:"",
          },
          {
            id:6,
            title:"郭氏暖手宝",
            cover:this.$mediaURL+'/ESER_guide/case5/07.webp',
            type:"",
          },
          {
            id:7,
            title:"多功能暖手宝",
            cover:this.$mediaURL+'/ESER_guide/case5/08.webp',
            type:"",
          },
          {
            id:8,
            title:"再玩暖腰带",
            cover:this.$mediaURL+'/ESER_guide/case5/09.webp',
            type:"",
          },
          {
            id:9,
            title:"暖杯垫",
            cover:this.$mediaURL+'/ESER_guide/case5/10.webp',
            type:"",
          },
          {
            id:10,
            title:"浴室暖风机",
            cover:this.$mediaURL+'/ESER_guide/case5/11.webp',
            type:"",
          },
          {
            id:11,
            title:"暖风机",
            cover:this.$mediaURL+'/ESER_guide/case5/12.webp',
            type:"",
          },


        ],
      ],
    }
  },
  mounted() {
    this.getCatalogIndex();
    this.init();
    this.imgModelPreloading();
    this.calculationWidth();
    // console.log("opencase",this.$route.params.case);
    // this.case_detail= this.$route.params.case;
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.38;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    getToPage(val){
      this.$router.push(val);
    },
    setFontSizeByBodyH(  h){
      return  "font-size:"+ this.htmlHeight/h + "px;";
    },
    getCatalogIndex(){
      var s = this.$route.params.title;
      for(var i=0;i<this.models.length;i++)
    {
      if( s==this.models[i].title_en.replace(/\s/g,"_") )
      {
        this.catalogSelect=i;
      }
    }
  },
    rebackCatalogPage(){
      this.$router.push({ path: '/services', meta: { flag: false }});
      // this.$router.push({name:'/services',params: {flag:false}})

    },
    openCaseDetail(value) {
      this.case_detail=value;
      this.caseShow=this.caseDetails[this.catalogSelect];
      this.$forceUpdate();
      var url=  '/caseDetail/'+this.models[this.catalogSelect].title_en.replace(/\s/g,"_")+'/'+this.caseDetails[this.catalogSelect][value].id;
      // localStorage.setItem('case_detail_id',value);
      this.$router.push(url);
      // this.$router.push({ path: url, params: { caseShow: this.caseShow} })
// 接收参数


    },
    refreashBtn(){

      this.isLoading=true;
      setTimeout(() =>{
        this.$forceUpdate();
        this.isLoading=false;
        this.case_detail=-1;
      }, 3800);
    },
    //计算每个图片的宽度或者是列数
    calculationWidth() {
      let domWidth=this.htmlWidth/1.4;
      // let domWidth = document.getElementById("v-waterfall").offsetWidth;
      if (!this.waterfallImgWidth && this.waterfallImgCol) {
        this.waterfallImgWidth = (domWidth - this.waterfallImgRight * this.waterfallImgCol) / this.waterfallImgCol;
      } else if (this.waterfallImgWidth && !this.waterfallImgCol) {
        this.waterfallImgCol = Math.floor(domWidth / (this.waterfallImgWidth + this.waterfallImgRight))
      }
      //初始化偏移高度数组
      this.waterfallDeviationHeight = new Array(this.waterfallImgCol);
      for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
        this.waterfallDeviationHeight[i] = 0;
      }
      this.imgPreloading();

      // this.imgCoverPreloading();
    },
    //图片预加载
    imgPreloading() {
      for (let i = 0; i < this.caseDetails[this.catalogSelect].length; i++) {

        let aImg = new Image();
        aImg.src =this.caseDetails[this.catalogSelect][i].cover;
        aImg.onload = aImg.onerror = () => {
          let imgData = {};
          imgData.orheight=aImg.height;
          imgData.orwidth=aImg.width;
          imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
          imgData.src =this.caseDetails[this.catalogSelect][i].cover;
          imgData.title = this.caseDetails[this.catalogSelect][i].title;
          imgData.id=i;
          imgData.top=0;
          this.waterfallList.push(imgData);


          // this.rankImg(imgData,i);
        }
      }
    },
    //waterfall stand
    imgModelPreloading() {
      for (let i = 0; i < this.waterfall_model.length; i++) {

        let aImg = new Image();
        aImg.src = this.waterfall_model[i].src;
        aImg.onload = aImg.onerror = () => {
          this.waterfall_model[i].height=aImg.height;
          this.waterfall_model[i].width= aImg.width;
        }
      }
    },
    getWaterfall_top(id)
    {

      if(id<this.waterfallImgCol)
      {
        this.waterfallList[id].top=0;
        return 0;
      }else
      {

      var col_padding=this.htmlHeight/28;
        var top =0;
        for(var col=0;col< Math.floor(id/this.waterfallImgCol) ;col++)
        {
          if((id%this.waterfallImgCol)%2==0)
          {
            //偶数列
            if(col%2==0)
            {
              top=top+(this.waterfallImgWidth /this.waterfall_model[0].width* this.waterfall_model[0].height)+col_padding;
            }else
            {
              top=top+(this.waterfallImgWidth /this.waterfall_model[1].width* this.waterfall_model[1].height)+col_padding;
            }
          }else
          {
            //奇数列
            if(col%2!=0)
            {
              top=top+(this.waterfallImgWidth /this.waterfall_model[0].width* this.waterfall_model[0].height)+col_padding;
            }else
            {
              top=top+(this.waterfallImgWidth /this.waterfall_model[1].width* this.waterfall_model[1].height)+col_padding;
            }
          }

        }
        this.waterfallList[id].top = top;

        return  top ;
      }
    },
    getWaterfall_left( id)
    {
      // return (id%this.waterfallImgCol ) * ( this.htmlWidth/48 + this.waterfallImgWidth);
      return (id%this.waterfallImgCol ) * (0 + this.waterfallImgWidth);

    },
    //瀑布流布局
    rankImg(imgData,i) {
      let {
        waterfallImgWidth,
        waterfallImgRight,
        waterfallImgBottom,
        // waterfallDeviationHeight,
        // waterfallImgCol,
      } = this;
      // let minIndex = this.filterMin();
      if(Math.floor(imgData.id/this.waterfallImgCol)>0)
      {
        imgData.top =this.waterfallList[i-this.waterfallImgCol].top+ this.waterfallList[i-this.waterfallImgCol].height+ waterfallImgBottom +this.htmlHeight/14 ;
      }else
      {
        imgData.top = Math.floor(imgData.id/this.waterfallImgCol);
      }

      imgData.left = (imgData.id%this.waterfallImgCol ) * (waterfallImgRight + waterfallImgWidth);
      // waterfallDeviationHeight[minIndex] += imgData.height + waterfallImgBottom;// 不加文字的盒子高度

    },
    /**
     * 找到最短的列并返回下标
     * @returns {number} 下标
     */
    filterMin() {
      const min = Math.min.apply(null, this.waterfallDeviationHeight);

      return this.waterfallDeviationHeight.indexOf(min);
    },
    overHiddenText( s,n){

      if(s.length>n)
      {
        return   s.substr(0,n)+'...';
      }else
      {
        return s;
      }
    },
    imgsPreload( type, imgs){
      if(type==2)
      {
        for(let img of imgs)
        {

          let image =new Image();
          image.src  =img;

          image.onload=()=>{
          }
        }
      }else if(type==0)
      {
        let image =new Image();
        image.src  =imgs;
        image.onload=()=>{
        }
      }

    },
  },
  //封面图片预加载
  imgCoverPreloading() {
    for (let i = 0; i < this.caseDetails.length; i++) {

      let aImg = new Image();
      aImg.src =this.caseDetails[i][1].cover;
      aImg.onload = aImg.onerror = () => {
        let imgData = {};
        imgData.orheight=aImg.height;
        imgData.orwidth=aImg.width;
        imgData.height = this.waterfallImgWidth / aImg.width * aImg.height;
        imgData.src =this.caseDetails[i][1].cover;
        imgData.title = this.caseDetails[i][1].title;// 说明文字（也可以自己写数组，或者封装json数据，都可以，但是前提是你会相关操作，这里不赘述）

        imgData.id=i;

      }
    }



  },
}
</script>

<style scoped>
.container_border_debug{
//border-radius: calc(100vh * 5 / 375);
  border: 0.5px solid rgba(255, 255, 255, 1);
  border-color: red;
}

.aside-img0 {
  background-size: 100% 100%;
  overflow: hidden;
  background: -webkit-linear-gradient(top,hsla(34,97%,66%,1),hsla(15,97%,58%,1),hsla(6,97%,54%,1))  no-repeat;
}
.aside-bg1 {
  background-size: 100% 100%;
  overflow: hidden;
  background: -webkit-linear-gradient(bottom,hsla(34,97%,66%,1),hsla(15,97%,58%,1),hsla(6,97%,54%,1))  no-repeat;
}
.aside-bg2 {
  background-size: 100% 100%;
  overflow: hidden;
  background: -webkit-linear-gradient(top,hsla(37,99%,64%,1),hsla(35,87%,66%,1),hsla(254,62%,53%,1))  no-repeat;
}
.aside-bg3 {
  background-size: 100% 100%;
  overflow: hidden;
  background: -webkit-linear-gradient(bottom,hsla(359,99%,53%,1),hsla(306,36%,46%,1),hsla(250,69%,54%,1))  no-repeat;
}
.aside-bg4 {
  background-size: 100% 100%;
  overflow: hidden;
  background: -webkit-linear-gradient(top,hsla(359,99%,53%,1),hsla(306,36%,46%,1),hsla(250,69%,54%,1))  no-repeat;
}
.flex_row_center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_vertical{
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.v-waterfall-content {
  /* 主要 */
  height: 100%;
  width: 100%;
  position: relative;
  /* 次要：设置滚动条，要求固定高度 */
//overflow-y: auto;
}
.v-waterfall-item {
  /* 主要 */
  float: left;
  position: absolute;

}
.case_card{

  border: 1px solid rgba(204, 204, 204, 0.2);
  background: rgba(255, 255, 255, 1);
}
</style>
