<template>
  <!--  &lt;!&ndash;  团队成员  &ndash;&gt;-->

  <div  style="justify-content: end;align-items: end;display: flex;" :style="getStyleByBodyWd(1,1)">
    <div :style="'margin-bottom:'+htmlHeight/12+'px;'+'margin-right:'+htmlHeight/16+'px;'"   style="position: absolute; z-index: 10;cursor: pointer;" @click="goToPage('/services')"   >
      <img :src="imgs.button" :style="getStyleByBodyWd(0,6)">
    </div>
    <div  style="display: flex;justify-content: center;align-items: center;"  :style="getStyleByBodyWd(1,1)" >
      <video
          x5-video-player-type="h5"
          x5-video-orientation="landscape"
          x5-video-player-fullscreen="true"
          webkit-playsinline   playsinline
          width="100%"
          muted="muted"  preload="auto"  loop="loop"  autoplay   :style="getStyleByBodyWd(1,1)"  style="width: 100%; height:100%; object-fit:fill">
        <source :src="imgs.video" type="video/mp4"  >
      </video>
    </div>

    <!--      <img :src="imgs.ward" :style="getStyleByBodyWd(1,1)">-->

  </div>

</template>

<script>
export default {
  name: "teamPage",
  data(){
    return{
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      imgs:{
        button:this.$mediaURL+'/ESER_guide/page0/button_r.gif',
         video:this.$mediaURL+'/ESER_guide/team/team0.mp4',
      },

    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    goToPage(val){
      this.$router.push(val);
    },
    setFontSizeByBodyH(  h){
      return  "font-size:"+ this.htmlHeight/h + "px;";
    },
  },
}

</script>

<style scoped>
.container_border_debug{
//border-radius: calc(100vh * 5 / 375);
  border: 0.5px solid rgba(255, 255, 255, 1);
  border-color: red;
}
.left_container{
  color: black;
  text-align: start;
}
.title_song_style{
  font-family: "思源宋体 Bold";
  font-weight: 900;
}
.title_red_cn{
  font-weight: 900;
  letter-spacing: 3px;
  color: rgba(255, 44, 45, 1);
  text-align: left;
  vertical-align: top;

}
.title_red_en{

  font-weight: 900;
  letter-spacing: 0px;
  color: rgba(255, 44, 45, 0.5);
  text-align: left;
  vertical-align: top;


}
</style>
