const $getStyleByBodyWd = (w,h) => {

        if(w==0)
            return  "height:"+ this.htmlHeight/h + "px;";
        else if(h==0)
            return "width:"+this.htmlWidth/w+ "px;";
        else
            return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";

}

export default {
    $getStyleByBodyWd
}

