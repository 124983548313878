<template>
  <div  style="display: flex;justify-content: center;align-items: center;color: black;" :style="getStyleByBodyWd(1,1)">
    <!--      close-button-->
    <div style="display: flex;justify-content: end;align-items: end;position: absolute; z-index: 10;cursor: pointer;"  :style="htmlHeight/16<36?'margin-top:-'+htmlHeight/1.12+'px;'+'margin-right:-'+htmlWidth/1.08+'px;': 'margin-top:-'+htmlHeight/1.08+'px;'+'margin-right:-'+htmlWidth/1.04+'px;'">
      <img src="../assets/close.png" :style="htmlHeight/16<36?'height:36px;':'height:'+htmlHeight/16+'px;'"  @click="goBack()"></div>
    <div :style="getStyleByBodyWd(1.06,1.08) "  style="display: flex;align-items: end; justify-content: center">
      <el-scrollbar   :style="getStyleByBodyWd(1.06,1.08)" >
        <el-row  style="display: flex;justify-content: space-between;align-items: center"   :style="getStyleByBodyWd(0,21.6)+'margin-top:'+htmlHeight/20+'px;'" >
          <el-col  :span="20" style="display: flex;align-items: start;flex-direction: column;justify-content: center" >
            <div :style="setFontSizeByBodyH(40) +'margin-bottom:-6px;'">以色工业设计——更懂电子产品的工业设计专家</div>
            <div   ><img :src="models[catalogSelect].detail_line" :style="getStyleByBodyWd(1.18,0)" style="height: 3px;"></div>
          </el-col>
          <el-col :span="4"  style="display: flex;justify-content: flex-end;"><img :src="models[catalogSelect].done" :style="getStyleByBodyWd(0,22)"></el-col>
        </el-row>
        <el-row  class="flex_row_between" :style="'margin-bottom:'+htmlHeight/16.36+'px;'+'margin-top:'+htmlHeight/20+'px;'">
          <div style="text-align: start;"  :style="getStyleByBodyWd(1.35,0)">
            <div>
              <div :style="setFontSizeByBodyH(20) +'margin-top:'+htmlHeight/40+'px;'+'margin-bottom:'+htmlHeight/40+'px;'" style="letter-spacing:3px;">{{caseShow.title}}</div>
            </div>
            <div :style="setFontSizeByBodyH(54)  +'line-height: '+htmlHeight/27+'px;'" style="color: rgba(75, 75, 75, 1);">
              服务内容：{{caseShow.type}}
            </div>
            <div :style="setFontSizeByBodyH(54) +'line-height: '+htmlHeight/27+'px;'" v-show="caseShow.info!=''" style="color: rgba(75, 75, 75, 1);">
              设计理念：{{caseShow.info}}
            </div>
          </div>
          <div  style="text-align: end;">
            <div  :style="setFontSizeByBodyH(38.5)">{{models[catalogSelect].title }}</div>
            <div :style="setFontSizeByBodyH(60)">{{models[catalogSelect].title_en}}</div>
          </div>
        </el-row>
        <el-row style="display: flex;justify-content: start" >
          <div v-for="(o,index) in caseShow.main" :key="index">
            <img :src="o" :style="getStyleByBodyWd(1.06,0)" >
          </div>

        </el-row>
      </el-scrollbar>
    </div>

  </div>

</template>

<script>
export default {
  name: "caseDetail",
  mounted() {
    this.getCatalogIndex();
    this.init();
    console.log(this.case_detail,this.catalogSelect);

  },

  data(){
    return{
      catalogSelect:0,
      case_detail:0,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      models:[
        {
          title:"家电家居",
          title_en:"Household Appliances",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow1.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line1.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_1.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_1.webp',
        },
        {
          title:"3C数码",
          title_en:"3C Digital Accessories",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow2.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line2.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_2.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_2.webp',
        },
        {
          title:"童品优选",
          title_en:"Children's Products",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow3.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line3.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_3.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_3.webp',
        },
        {
          title:"个护健康",
          title_en:"Personal Healthcare",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow4.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line3.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_4.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_4.webp',
        },
        {
          title:"两季产品",
          title_en:"Seasonal Products",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow5.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line4.webp',
          done:this.$mediaURL+'/ESER_guide/catalog/icons/done_5.webp',
          detail_line: this.$mediaURL+'/ESER_guide/catalog/icons/line_5.webp',
        },
        {
          title:"品牌全案设计",
          title_en:"Brand Design",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow6.webp',
          line:"",
          done:"",
          detail_line: "",
        },
      ],
      caseShow:{},
      caseDetails:[
        [
          {
            id:0,
            title:"华为智选乐骑智能电动滑板车",
            cover:this.$mediaURL+'/ESER_guide/case1/01.webp',
            type:"外观设计",
            info:'整体车身设计灵感源于奔腾的马背，以流畅的弓字造型打造棱角分明的腰线，赋予车身力量感、耐用和速度感。\n' +
                '采用汽车级一体化工艺，坚固的铝合金保护壳，IPX7级防水电池。\n' +
                '近距离靠近即开启灯效，智能夜行灯搭配两侧LED灯珠构成的氛围灯。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/01/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/01/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/01/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/01/4.webp',
            ],
          },
          {
            id:1,
            title:"Tribit户外音箱",
            cover:this.$mediaURL+'/ESER_guide/case1/02.webp',
            type:"外观设计",
            info:'顶部二次圆弧角的方形设计，与整体造型相得益彰。方圆之间，尽显流畅饱满。立体“水壶式”设计，音箱四周IPX7深度级别防水网布面罩，在保证透气利于声音传播的同时，顽强抵御水的入侵，由内而外透露出安全稳重感。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/02/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/02/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/02/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/02/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/02/5.webp',
              this.$mediaURL+'/ESER_guide/case1/main/02/6.webp',
            ],
          },
          {
            id:2,
            title:"户外蓝牙音箱",
            cover:this.$mediaURL+'/ESER_guide/case1/03.webp',
            type:"外观设计",
            info:'大面积低亮度、暗黑基调的主体设计，正面高饱和度的霓虹灯点缀。融合酷炫的科技风与变幻莫测的光影，碰撞出奇异的美感。\n' +
                '音箱主体采用柔和的线条设计，采用轻量化调整把手，减轻体块感，增加孔洞作为吊扣孔。正面采用铁网设计做包裹，侧面灯光贴合铁网造型，更具统一感。\n' +
                '音箱造型线条取自豪车侧面线条，流畅舒适；两侧灯光采用与车灯类似的三角造型，塑造更强的动感，让用户更直观地感受到音乐的律动。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/03/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/03/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/03/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/03/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/03/5.webp',
            ],
          },
          {
            id:3,
            title:"倍特力储能电源",
            cover:this.$mediaURL+'/ESER_guide/case1/04.webp',
            type:"外观设计",
            info:'以“新能源、新风貌”为设计理念。摒弃传统电源的工程设备感，追求简约、精致、品质感的现代主流审美。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/04/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/04/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/04/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/04/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/04/5.webp',
              this.$mediaURL+'/ESER_guide/case1/main/04/6.webp',
            ],
          },
          {
            id:4,
            title:"地毯清洁机",
            cover:this.$mediaURL+'/ESER_guide/case1/05.webp',
            type:"外观设计",
            info:'设计强调现代感和硬朗感，采用简洁的线条和坚固的外形，创造出适应现代家居的外观。外观融入了科技感和机械感的元素，赋予产品未来感，提升用户清洁的科技体验。\n' +
                '采用先进的清洁技术，能够深入地毯纤维，高效清除污渍和灰尘。设备内置多重安全保护机制，确保在使用过程中的安全。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/05/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/05/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/05/3.webp',
            ],
          },
          {
            id:5,
            title:"倍特力户外电源",
            cover:this.$mediaURL+'/ESER_guide/case1/06.webp',
            type:"外观设计",
            info:'参考西装马甲笔挺的外轮廓，打造整体外观线条，顶面设计“银耳”造型提手，灵感取自奥迪RS车型的别称“奥迪银耳”，凸显其高性能特点，商务范十足。\n' +
                '休闲和商务两不误，顶面及侧面结合拆件方式采用大面积流瀑式散热条设计，由手风琴的“风箱”造型演变而来，更赋予其灵动轻巧之美。\n' +
                '左侧散热孔阵完美融入格栅之中，右侧与底部LED灯通过格栅的连接打造圆形特征，直曲相宜。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/06/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/06/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/06/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/06/4.webp',
            ],
          },
          {
            id:6,
            title:"魔方会议音箱",
            cover:this.$mediaURL+'/ESER_guide/case1/07.webp',
            type:"外观设计",
            info:'外观灵感来源于魔方，呈现出立体感和立方体的几何美。整体呈方形形态，稳重而不失现代感。在方形的一角切出小方形，为产品增加独特的设计特色，同时也是操作按键的区域。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/07/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/07/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/07/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/07/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/07/5.webp',
              this.$mediaURL+'/ESER_guide/case1/main/07/6.webp',
            ],
          },
          {
            id:7,
            title:"立式投影仪",
            cover:this.$mediaURL+'/ESER_guide/case1/08.webp',
            type:"外观设计",
            info:'整体设计取自都市中繁华的高楼大厦，笔直沉稳的轮廓线条，酷似大楼外墙体长条形线条灯，既起到散热作用，又具备装饰效果。\n' +
                '投影仪的镜头及周围部件，灵感汲取自幕墙透明LED显示屏，只为匠心呈现一场充满梦幻色彩的灯光秀。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/08/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/08/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/08/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/08/4.webp',

            ],
          },
          {
            id:8,
            title:"干果机",
            cover:this.$mediaURL+'/ESER_guide/case1/09.webp',
            type:"外观设计",
            info:'以硬朗和现代感为核心，融入透明视窗，让用户随时观察干果烘烤的过程，增加互动性和使用乐趣。使用不锈钢材质，提升产品的耐用性和坚固感，同时赋予外观高贵光泽。旨在提供用户卓越的使用体验，同时赋予产品坚固性和时尚感，将现代感和工业风融入外观设计。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/09/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/09/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/09/3.webp',
            ],
          },
          {
            id:9,
            title:"烤箱",
            cover:this.$mediaURL+'/ESER_guide/case1/10.webp',
            type:"外观设计",
            info:'设计强调烤箱的专业感和品质感，通过简洁的线条和高质感的材料，创造出专业厨房的氛围。\n' +
                '采用隐藏式把手设计，使外观更加整洁，同时减少了碰撞风险，提升了安全性。\n' +
                '宽敞的烤箱腔体，满足各种烹饪需求。设备操作界面简单直观，用户轻松选择不同的烹饪模式和温度，实现精准烹饪。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/10/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/10/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/10/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/10/4.webp',

            ],
          },
          {
            id:10,
            title:"空气炸锅",
            cover:this.$mediaURL+'/ESER_guide/case1/11.webp',
            type:"外观设计",
            info:'设计主调以硬朗的线条和极简的造型为特点，以硬朗和极简的欧美风格为基础，融入金属质感，为用户带来直观、畅快的烹饪体验。\n' +
                '外观以金属质感为基础，让产品看起来更具高贵品质，也增添了烹饪的仪式感。\n' +
                '超大容量设计，满足多人用餐或家庭聚会的需要，设备配备直观的操作界面，让用户轻松掌握烹饪过程，即便是新手也能轻松上手。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/11/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/11/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/11/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/11/4.webp',

            ],
          },
          {
            id:11,
            title:"绞肉机",
            cover:this.$mediaURL+'/ESER_guide/case1/12.webp',
            type:"外观设计",
            info:'设计采用方圆弧的造型特征，既保留了柔和的外观，又凸显了现代感，使产品融入各种厨房环境。\n' +
                '设备在中部进行轻微的收腰设计，不仅增加了视觉的流畅感，也更符合人体工程学，提升了握持的舒适性。\n' +
                '设计注重用户体验，确保操作简便、清洁方便，为用户提供顺畅的使用感受。\n' +
                '在握持部分采用硅胶材质，增加握持的舒适性和防滑性，让用户的使用更加安心。\n' +
                '关键部件选用不锈钢材质，保证产品的耐用性和卫生性，适应多种食材的处理。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/12/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/12/2.webp',

            ],
          },
          {
            id:12,
            title:"陶瓷炖锅",
            cover:this.$mediaURL+'/ESER_guide/case1/13.webp',
            type:"外观设计",
            info:'采用时尚的配色方案，设计了两个独立的炖位，可同时烹饪多种食材，满足不同口味的需求。\n' +
                '设备配备多种烹饪模式，如慢炖、煮粥、炖汤等，适应不同食材和口味的烹饪需求。\n' +
                '操作界面简单直观，让用户轻松掌握烹饪流程，即使是新手也能轻松上手。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/13/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/13/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/13/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/13/4.webp',

            ],
          },
          {
            id:13,
            title:"便携式电饭锅",
            cover:this.$mediaURL+'/ESER_guide/case1/14.webp',
            type:"外观设计",
            info:'设计以圆润的外观、桶状的形态、家居风格为基础，结合单边把手和大圆形显示区域，为用户打造便捷、美观的烹饪体验。顶部设计宽大的圆形显示区域，能够清晰地查看烹饪状态，操作更加方便。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/14/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/14/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/14/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/14/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/14/5.webp',
              this.$mediaURL+'/ESER_guide/case1/main/14/6.webp',
            ],
          },
          {
            id:14,
            title:"空气净化器",
            cover:this.$mediaURL+'/ESER_guide/case1/15.webp',
            type:"外观设计",
            info:'设计采用极简现代风格，注重线条的简洁和形状的几何感，使产品与现代家居融合。外观呈方形，使其更易于放置在各种家居空间中，符合现代家居的审美。\n' +
                '内置高效净化系统，能够有效去除室内的细颗粒物和污染物，提供清新的室内空气。设备配备智能控制系统，可根据室内空气质量自动调整工作模式，提供更加智能化的使用体验',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/15/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/15/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/15/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/15/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/15/5.webp',

            ],
          },
          {
            id:15,
            title:"车载净化器",
            cover:this.$mediaURL+'/ESER_guide/case1/16.webp',
            type:"外观设计",
            info:'设计融合了碳纤维、现代、简洁的特点，结合UV灯消毒和臭氧消毒技术，为车内带来清新、健康的空气。通过高科技的设计和先进的技术，提升驾驶者的驾车体验，创造一个舒适、洁净的车内环境。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/16/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/16/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/16/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/16/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/16/5.webp',

            ],
          },
          {
            id:16,
            title:"手持吸尘器",
            cover:this.$mediaURL+'/ESER_guide/case1/17.webp',
            type:"外观设计",
            info:'充满科技感和线条感的外观设计，在完整性较高的主体上进行线条刻画，同时与轮廓线相关联，使产品具有向前的动势和力量感，手柄处采用人体工学设计，平整圆润的曲面保证操作的舒适性。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/17/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/17/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/17/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/17/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/17/5.webp',

            ],
          },
          {
            id:17,
            title:"挂烫机",
            cover:this.$mediaURL+'/ESER_guide/case1/18.webp',
            type:"外观设计",
            info:'',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/18/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/18/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/18/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/18/4.webp',

            ],
          },
          {
            id:18,
            title:"除湿机",
            cover:this.$mediaURL+'/ESER_guide/case1/19.webp',
            type:"外观设计",
            info:'突破性的造型设计，通过几何形体的穿插叠合，头部融合显示屏幕，中间部分设计为水箱，在顶壳抽出后可以做为手柄进行竖向使用。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/19/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/19/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/19/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/19/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/19/5.webp',

            ],
          },
          {
            id:19,
            title:"电火锅",
            cover:this.$mediaURL+'/ESER_guide/case1/20.webp',
            type:"外观设计",
            info:'外形为独特的锥桶状，稳定性高，外观采用金属材质，赋予了产品高级的外观质感，与现代家居搭配无缝融合。顶部特别设计了环绕式的圆形灯圈，增添装饰性，同时也能提供柔和照明，营造舒适氛围。\n' +
                '\n' +
                '正面设有透明视窗，方便用户随时查看湿度指示和工作状态，增加了用户与产品的互动性。内部装备先进的除湿技术，迅速有效地降低室内湿度，为居住环境创造舒适条件。顶部的环形灯圈不仅可用于提供照明，还可以根据用户需求调整颜色和亮度，为空间增添多样性。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/20/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/20/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/20/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/20/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/20/5.webp',

            ],
          },
          {
            id:20,
            title:"置物架系列化",
            cover:this.$mediaURL+'/ESER_guide/case1/21.webp',
            type:"外观设计",
            info:'这是一款专为火锅爱好者设计的一体式电煮锅，设计灵感来源于宫廷器皿，外观饱满圆润。在操控方式上脱离传统的旋钮，采用拨动的方式，给予用户新奇体验。细节上锅身把手和顶部把手纹理互相呼应，指示界面采用了复古钟表样式，搭配金色喷漆材质。\n' +
                '细节处以“花”为灵感来源，结合古典家具的高脚设计，呈现复古轻奢的设计理念。\n' +
                '多处花朵元素的出现，花瓣元素运用到了按键处，花的四瓣指向电煮锅的四个功能。升级款将花的元素运用到了侧面和顶部把手上。',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/21/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/4.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/5.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/6.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/7.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/8.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/9.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/10.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/11.webp',
              this.$mediaURL+'/ESER_guide/case1/main/21/12.webp',
            ],
          },
          {
            id:21,
            title:"叠加式水壶",
            cover:this.$mediaURL+'/ESER_guide/case1/22.webp',
            type:"外观设计",
            info:'置物架整体呈现圆润简洁的外观，跑道圆和圆形元素巧妙融合，使设计更具层次感和创意。\n' +
                '\n' +
                '推出多种尺寸、颜色和风格的置物架，满足不同用户的需求。系列中的每个置物架都保持统一的设计语言，增强整体协调性。适用于不同房间和用途，如客厅、卧室、书房等，为家居提供更多收纳解决方案',
            main:[
              this.$mediaURL+'/ESER_guide/case1/main/22/1.webp',
              this.$mediaURL+'/ESER_guide/case1/main/22/2.webp',
              this.$mediaURL+'/ESER_guide/case1/main/22/3.webp',
              this.$mediaURL+'/ESER_guide/case1/main/22/4.webp',

            ],
          },
        ],
        [
          {
            id:0,
            title:"努比亚方糖快速充电器",
            cover:this.$mediaURL+'/ESER_guide/case2/01.webp',
            type:"外观设计",
            info:'糖果般大小随身便携，表面亮面处理如同透明外壳，既轻巧又优雅。 清新色彩搭配，无疑是充电器中的颜值担当。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/01/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/01/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/01/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/01/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/01/5.webp',
              this.$mediaURL+'/ESER_guide/case2/main/01/6.webp',
              this.$mediaURL+'/ESER_guide/case2/main/01/7.webp',
            ],
          },
          {
            id:1,
            title:"澳德鸿二合一可折叠无线充",
            cover:this.$mediaURL+'/ESER_guide/case2/02.webp',
            type:"外观设计",
            info:"'多功能充电器，轻松解决多条数据线的收纳问题。塑胶搭配皮质材质凸显质感，软性皮质可折叠收纳不占空间。\n" +
                "将多重功能融合在一个简约的外观中，创造一个二合一折叠磁吸充电底座，采用极简现代的风格设计，将产品的简约、时尚、便携和轻巧融为一体，创造出一个融合美感和实用性的充电底座。\n" +
                "设计具有折叠功能，使底座在不使用时可以轻松折叠起来，方便携带。充电底座集成了两种充电方式，支持同时为两个设备充电，提高充电效率",
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/02/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/5.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/6.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/7.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/8.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/9.webp',
              this.$mediaURL+'/ESER_guide/case2/main/02/10.webp',
            ],
          },
          {
            id:2,
            title:"桌面充电器",
            cover:this.$mediaURL+'/ESER_guide/case2/03.webp',
            type:"外观设计",
            info:'在提供高效充电功能的同时，融入跑道圆形态和圆润柔和的外观设计，使产品在实用性和美观性上达到完美的平衡。注重线条的圆润和柔和，使产品在外观上更加亲切、温暖。\n' +
                '磁吸无线充和多个USB接口的功能则提供了更高效的充电解决方案。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/03/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/03/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/03/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/03/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/03/5.webp',
            ],
          },
          {
            id:3,
            title:"今翔磁吸充电宝",
            cover:this.$mediaURL+'/ESER_guide/case2/04.webp',
            type:"外观设计",
            info:'采用金属转轴搭配皮革支架，可多角度调节，皮革与金属的材质对比凸显产品高级感。套装造型简洁且整体设计语言统一性较强.',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/04/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/04/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/04/3.webp',
            ],
          },
          {
            id:4,
            title:"今翔无线充排插",
            cover:this.$mediaURL+'/ESER_guide/case2/05.webp',
            type:"外观设计",
            info:'在提供多种充电解决方案的基础上，融入三角形的外观设计和包布正面，创造出一个高效、美观的家居电源和充电设备，为家居增添实用性和美感。\n' +
                '设计注重用户体验。磁吸无线充功能、USB接口和AC接口的融合，使产品具备了全方位的充电能力，三角形形态和包布正面则增加了产品的美感和亲和感。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/05/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/05/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/05/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/05/4.webp',
            ],
          },
          {
            id:5,
            title:"今翔三合一快充",
            cover:this.$mediaURL+'/ESER_guide/case2/06.webp',
            type:"外观设计",
            info:'外观设计注重简约，强调功能性，表面采用精细的加工工艺，呈现出高品质的触感和视觉效果。可折叠收纳，便于携带和储存，为用户的生活增添便利。集成了三种不同类型的充电接口，满足用户多种设备的充电需求。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/06/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/06/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/06/3.webp',
            ],
          },
          {
            id:6,
            title:"今翔150w商务移动电源",
            cover:this.$mediaURL+'/ESER_guide/case2/07.webp',
            type:"外观设计",
            info:'整体造型简约，在主体塑胶件上进行两种不同的模拟相机质感的晒纹处理，丰富整体细节，提升产品的品质感。\n' +
                '在保持现代简约的外观设计基础上，为用户提供高功率输出和出色的便携性。皮质提手为产品增加了视觉温度，还提升了携带的舒适感。\n' +
                '多个输出接口，如USB、AC插座等，支持不同类型设备的充电，为用户在户外活动和紧急情况下提供可靠的电力支持。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/07/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/07/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/07/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/07/4.webp',
            ],
          },
          {
            id:7,
            title:"会议音频",
            cover:this.$mediaURL+'/ESER_guide/case2/08.webp',
            type:"外观设计",
            info:'部分特征元素来源于飞行器的设计，为整体外观增添了创新的趣味性和科技感。\n' +
                '线条流畅、动感十足，强调现代感和高科技感，为会议室增添独特的现代氛围。融入未来感的元素，让设备在会议场景中显得先进、高科技。\n' +
                '设备配备先进的音频技术，提供清晰、高保真的音质，确保会议参与者能够听到每一个细节。具备多种音频输入和输出选项，适应不同类型的会议需求，包括语音、音乐等。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/08/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/08/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/08/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/08/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/08/5.webp',
              this.$mediaURL+'/ESER_guide/case2/main/08/6.webp',
            ],
          },
          {
            id:8,
            title:"互拓无线麦克风",
            cover:this.$mediaURL+'/ESER_guide/case2/09.webp',
            type:"外观设计",
            info:'整体呈简洁工业风格，外观采用叠加包裹的形式，丰富层次感，运用皮革纹理丰富产品细节。\n' +
                '将旋钮作为视觉特征点进行设计，并以其作为音量操作功能。设计强调沉稳商务风格，采用稳重的色彩和简洁的线条，使产品适用于商务场合。\n' +
                '通过几何形体的运用和旋钮亮色材质的设计，赋予产品独特的外观，体现现代硬朗感。\n' +
                '设计支持无线连接，提供更大的移动自由度，使用户的演讲更加灵活。融入专业音频技术，确保出色的声音捕捉和传输，为用户带来卓越的音频体验。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/09/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/09/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/09/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/09/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/09/5.webp',
              this.$mediaURL+'/ESER_guide/case2/main/09/6.webp',
            ],
          },
          {
            id:9,
            title:"电吹管",
            cover:this.$mediaURL+'/ESER_guide/case2/10.webp',
            type:"外观设计",
            info:'演奏按键灵感源自章鱼的八只爪子，整体造型与章鱼修长的身材如出一辙，银灰色、湛蓝色、曜石黑的配色高级感十足。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/10/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/10/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/10/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/10/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/10/5.webp',
            ],
          },
          {
            id:10,
            title:"复古收音机",
            cover:this.$mediaURL+'/ESER_guide/case2/11.webp',
            type:"外观设计",
            info:'整体造型采用微弧曲面设计，与上方香薰灯相呼应，衔接自然。复刻的表盘元素结合电镀件旋钮和按键，诠释复古新时尚。\n' +
                '同色系撞色处理，亮面塑胶材质与电镀金属色对比增添层次感。\n' +
                '作为一款应急收音机，灵活的Type-C接口充电、手摇充电、太阳能板充电功能，随时随地不断电，USB大功率输出，反向为手机和其他数码产品充电。\n' +
                '推杆用于收音机调频，可选择AM、FM、WB、SW频道。收音机配有SOS应急照明灯和LED灯，通过旋钮调节灯光亮度，可作为工作灯、氛围灯使用。\n' +
                '收音机顶部支持安装配件，即可变身充满仪式感的香薰灯。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/11/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/11/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/11/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/11/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/11/5.webp',
            ],
          },
          {
            id:11,
            title:"猫耳头戴式耳机",
            cover:this.$mediaURL+'/ESER_guide/case2/12.webp',
            type:"外观设计",
            info:'设计旨在融合简约可爱的风格，采用可折叠设计，方便用户在不使用时进行收纳，增加便携性。造出一个简约可爱的整体风格，为用户的音乐体验增添乐趣和情感。\n' +
                '\n' +
                '在头戴的两侧增加小耳朵元素，小耳朵上的星星元素搭配LED氛围灯，为耳机增加时尚和趣味，同时也可在低光环境中提供一种独特的视觉效果。\n' +
                '\n' +
                '耳罩的外形设计成月亮的形状特征，与星星元素相呼应，创造出整体的主题氛围。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/12/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/12/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/12/3.webp',
            ],
          },
          {
            id:12,
            title:"安全摄像头",
            cover:this.$mediaURL+'/ESER_guide/case2/13.webp',
            type:"外观设计",
            info:'以方倒圆形态为主体，正面采用简洁、方正的设计语言，背面圆润、柔和，确保不同视角的不同观感，满足户外为主，兼顾室内的需求。\n' +
                '同时将手持云台的多轴转动方式简化应用，产品更为灵活新颖。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/13/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/13/2.webp',
            ],
          },
          {
            id:13,
            title:"直播灯",
            cover:this.$mediaURL+'/ESER_guide/case2/14.webp',
            type:"外观设计",
            info:'设计融合了落地式、可调节高度、圆形灯圈、温润手感和极简的特点，旨在为直播者提供一款功能强大且美观的灯具，创造出完美的照明效果，同时突显现代时尚感。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/14/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/14/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/14/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/14/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/14/5.webp',
              this.$mediaURL+'/ESER_guide/case2/main/14/6.webp',
            ],
          },
          {
            id:14,
            title:"组创微扫描仪",
            cover:this.$mediaURL+'/ESER_guide/case2/15.webp',
            type:"外观设计",
            info:'将实体与数字融合，以温馨简洁的外观为基础，通过圆润的处理和直观的屏幕功能，为用户带来温暖而便捷的数字体验，创造出一个手感温和、操作直观的扫描工具。\n' +
                '设计圆润的跑道圆形态，将外观与功能融为一体，使操作更加自然直观。具备实体扫描功能，用户可方便地将实物文档快速转化为数字格式。扫描笔头部结合屏幕显示功能，让用户能够实时查看扫描的内容，提高操作效率。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/15/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/15/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/15/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/15/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/15/5.webp',
            ],
          },
          {
            id:15,
            title:"中云景星智能哑铃",
            cover:this.$mediaURL+'/ESER_guide/case2/16.webp',
            type:"外观设计",
            info:'设计灵感来源于钢铁侠，取其力量感为出发点。\n' +
                '主体采用正六边形设计，底座形状相呼应，硬朗的线条凸显哑铃力量感。\n' +
                '两侧灯光形状酷似钢铁侠心脏，棱线切割丰富层次感，撞色搭配尽显活力。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/16/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/16/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/16/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/16/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/16/5.webp',
            ],
          },
          {
            id:16,
            title:"绿巨能HDMI高清链接线",
            cover:this.$mediaURL+'/ESER_guide/case2/17.webp',
            type:"外观设计",
            info:'灵感源于十大名剑中的承影剑，曲面与折线赋予产品剑脊的优美弧度和剑从的锋利感。外壳UV电镀工艺耐氧化不留指纹。\n' +
                '融合多切面设计、机械感、硬朗元素和未来感为核心，赋予产品动态感，从不同角度都呈现出独特的光影效果，创造一款独特的HDMI线材。融入未来感元素，传递出科技和未来的前沿感。',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/17/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/17/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/17/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/17/4.webp',
              this.$mediaURL+'/ESER_guide/case2/main/17/5.webp',
            ],
          },
          {
            id:17,
            title:"数据线",
            cover:this.$mediaURL+'/ESER_guide/case2/18.webp',
            type:"外观设计",
            info:'',
            main:[
              this.$mediaURL+'/ESER_guide/case2/main/18/1.webp',
              this.$mediaURL+'/ESER_guide/case2/main/18/2.webp',
              this.$mediaURL+'/ESER_guide/case2/main/18/3.webp',
              this.$mediaURL+'/ESER_guide/case2/main/18/4.webp',
            ],
          },
        ],
        [
          {
            id:0,
            title:"再玩儿童洗牙器",
            cover:this.$mediaURL+'/ESER_guide/case3/01.webp',
            type:"外观设计",
            info:'设计灵感来源机器人和儿童学习中简单的加减乘除，通过圆与乘号的组合使产品更具童趣。\n' +
                '参考米菲兔形象特点，融入洗牙器设计里。\n' +
                '简洁的轮廓线条，正面甜甜圈和十字花瓣图形生动地勾勒出整体呆萌形象，两侧圆圈耳朵装饰，俏皮、可爱。\n' +
                '颜色上主要采用红、橘、蓝、黄、绿的清新配色，充满活泼气息。\n' +
                '产品整体为简洁的柱体，顶部采用流畅的曲线做收缩设计。\n' +
                '主体部分采用塑胶，与大面积的软胶结合，在材质上有所区分，也可进行撞色搭配。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/01/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/01/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/01/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/01/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/01/5.webp',
            ],
          },
          {
            id:1,
            title:"儿童水杯",
            cover:this.$mediaURL+'/ESER_guide/case3/02.webp',
            type:"外观设计",
            info:'设计上将温度控制与可爱的小鲸鱼元素相结合，创造了一个关心孩子们健康的设计理念。水杯外壳采用鲸鱼的造型，设计注重圆润的线条和温和的表情，吸管与鲸鱼换气时候的状态融为一体，使得鲸鱼在饮水时好似在吐水，增加了趣味性。水杯的色彩鲜艳明亮，如海洋蓝、阳光黄等，增加了视觉吸引力，激发孩子们的兴趣。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/02/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/02/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/02/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/02/4.webp',
            ],
          },
          {
            id:2,
            title:"学伴互联电子摇铃",
            cover:this.$mediaURL+'/ESER_guide/case3/03.webp',
            type:"外观设计",
            info:'本款产品以企鹅形象为设计灵感，俏皮的表情、圆润的体态搭配领结设计，整体憨态可掬。灯光区和企鹅的圆肚皮结合，巧妙有趣。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/03/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/03/2.webp',
            ],
          },
          {
            id:3,
            title:"轮马克智能语音款儿童牙刷",
            cover:this.$mediaURL+'/ESER_guide/case3/04.webp',
            type:"外观设计",
            info:'以可爱搞怪的兔子为设计原型，造型生动活泼，采用专利U型设计，360°清洁，食品级硅胶材质Q弹不伤牙龈。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/04/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/04/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/04/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/04/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/04/5.webp',
            ],
          },
          {
            id:4,
            title:"墨趣儿童音箱",
            cover:this.$mediaURL+'/ESER_guide/case3/05.webp',
            type:"外观设计",
            info:'以米奇，兔子，猫咪三种动物耳朵为主要特征，提取动物的表情特点，将表情拟人化丝印到产品上，整体采用一体式的硅胶头套将内部包裹起来。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/05/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/05/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/05/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/05/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/05/5.webp',
            ],
          },
          {
            id:5,
            title:"儿童手拍鼓",
            cover:this.$mediaURL+'/ESER_guide/case3/06.webp',
            type:"外观设计",
            info:'手拍鼓造型像一只蹲在树上的猫头鹰，萌趣可爱，给人一种想要摸摸头的心理感受。\n' +
                '设有八个按键，除了调换功能键和歌曲键，还加入了对应六种不同小动物和旋律的按键，图形结合字符的表现方式，让宝宝可以自由选择专属于他的旋律。\n' +
                '拍鼓区域做成猫爪形状，颜色采用清新而又温馨的颜色进行搭配，亲和而不刺眼。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/06/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/06/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/06/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/06/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/06/5.webp',
            ],
          },
          {
            id:6,
            title:"儿童K歌麦克风",
            cover:this.$mediaURL+'/ESER_guide/case3/07.webp',
            type:"外观设计",
            info:'外观充满软萌氛围，通过圆润的线条和柔和的色彩，为产品赋予了可爱的外观特点。顶部设计了两个小耳朵，增加了独特的视觉吸引力，让整体外观更加生动。部分装饰件采用电镀工艺，不仅提升了外观的精致感，还为产品增添了一份时尚氛围。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/07/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/07/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/07/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/07/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/07/5.webp',
              this.$mediaURL+'/ESER_guide/case3/main/07/6.webp',
              this.$mediaURL+'/ESER_guide/case3/main/07/7.webp',
            ],
          },
          {
            id:7,
            title:"点读笔",
            cover:this.$mediaURL+'/ESER_guide/case3/08.webp',
            type:"外观设计",
            info:'在点读笔的按键设计上，选用五角星的形状作为按键，寓意着每个按键都是一个知识的点滴。每个五角星按键都以不同的颜色标识，使孩子们可以轻松区分不同的功能。\n' +
                '点读笔的顶部设计为一个可爱的大头萌娃造型，具有圆润的脸庞、大眼睛和温暖的微笑。这个萌娃形象能够引发孩子们的共鸣，让他们在学习的同时感受到愉悦和陪伴。\n' +
                '采用鲜艳的色彩，如橙色、蓝色、绿色等，以增强产品的视觉吸引力，吸引孩子们的注意力。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/08/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/08/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/08/3.webp',
            ],
          },
          {
            id:8,
            title:"德兰达儿童磨甲器",
            cover:this.$mediaURL+'/ESER_guide/case3/09.webp',
            type:"外观设计",
            info:'灵感来源于儿童积木的几何拼接，方与圆的结合，纯粹简约，让指尖魔法不失童趣。圆润的造型更具亲和力，柔软硅胶材质舒适安全。档位调速，可控调节，更具安全感。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/09/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/09/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/09/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/09/4.webp',
            ],
          },
          {
            id:9,
            title:"K歌麦",
            cover:this.$mediaURL+'/ESER_guide/case3/10.webp',
            type:"外观设计",
            info:'',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/10/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/10/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/10/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/10/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/10/5.webp',
            ],
          },
          {
            id:10,
            title:"儿童相机",
            cover:this.$mediaURL+'/ESER_guide/case3/11.webp',
            type:"外观设计",
            info:'外观以时尚元素为主，圆柱形状设计，既能容纳眼镜，又具有简约美感。轻巧便携，适合随身携带，随时保持眼镜清洁。采用超声波清洁技术，能够高效去除眼镜表面的污垢和细菌。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/11/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/11/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/11/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/11/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/11/5.webp',
              this.$mediaURL+'/ESER_guide/case3/main/11/6.webp',
              this.$mediaURL+'/ESER_guide/case3/main/11/7.webp',
              this.$mediaURL+'/ESER_guide/case3/main/11/8.webp',
            ],
          },
          {
            id:11,
            title:"儿童闹钟",
            cover:this.$mediaURL+'/ESER_guide/case3/12.webp',
            type:"外观设计",
            info:'',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/12/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/12/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/12/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/12/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/12/5.webp',
            ],
          },
          {
            id:12,
            title:"雾化器",
            cover:this.$mediaURL+'/ESER_guide/case3/13.webp',
            type:"外观设计",
            info:'雾化器整体造型的灵感来源于豌豆射手，正所谓爱玩是孩子的天性，在产品设计里融入游戏、动画的元素，更能给予儿童亲切感。就像豌豆射手抵御着僵尸的入侵，雾化器也在守护着孩子们的健康。\n' +
                '配色采用渐变色，搭配儿童喜爱的红、蓝、黄等艳丽明快的颜色，渐变视觉更显柔和。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/13/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/13/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/13/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/13/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/13/5.webp',
              this.$mediaURL+'/ESER_guide/case3/main/13/6.webp',
            ],
          },
          {
            id:13,
            title:"学习桌",
            cover:this.$mediaURL+'/ESER_guide/case3/14.webp',
            type:"外观设计",
            info:'木材和金属的结合，既保持了自然温馨的木质感，又赋予了现代感和稳定性。升降高度调节，适应不同年龄段儿童的身高需求，提供舒适的学习姿势。强大置物功能提供了充足的存储空间，让儿童的书籍、文具和物品都有合适的存放位置。学习和手工区域的划分，满足儿童多样化的学习和创造需求，激发创意和好奇心。',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/14/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/14/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/14/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/14/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/14/5.webp',
              this.$mediaURL+'/ESER_guide/case3/main/14/6.webp',
              this.$mediaURL+'/ESER_guide/case3/main/14/7.webp',
            ],
          },
          {
            id:14,
            title:"游戏机",
            cover:this.$mediaURL+'/ESER_guide/case3/15.webp',
            type:"外观设计",
            info:'',
            main:[
              this.$mediaURL+'/ESER_guide/case3/main/15/1.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/2.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/3.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/4.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/5.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/6.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/7.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/8.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/9.webp',
              this.$mediaURL+'/ESER_guide/case3/main/15/10.webp',
            ],
          },

        ],
        [
          {
            id:0,
            title:"射频美容仪",
            cover:this.$mediaURL+'/ESER_guide/case4/01.webp',
            type:"外观设计",
            info:'以中空镂空的圆形为基础，赋予产品轻盈感，也创造出独特的外观特征。机身采用柔和的曲线设计，舒适握持的同时，也为产品增加流畅感。在外观上融入装饰感的元素，不仅增强视觉吸引力，也彰显时尚与个性。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/01/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/01/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/01/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/01/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/01/5.webp',
            ],
          },
          {
            id:1,
            title:"轮马克可视化黑头仪",
            cover:this.$mediaURL+'/ESER_guide/case4/02.webp',
            type:"外观设计",
            info:'轻奢复古风格，电镀装饰和烫金工艺提升产品精致感。正面防滑纹理搭配流行的马卡龙和复古色更添时尚感。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/02/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/02/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/02/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/02/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/02/5.webp',
            ],
          },
          {
            id:2,
            title:"脱毛仪",
            cover:this.$mediaURL+'/ESER_guide/case4/03.webp',
            type:"外观设计",
            info:'采用穿插结构设计，侧面是方形渐变的散热孔，整体上适当使用电镀件做点缀，材质上亮雾面区分增加品质感。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/03/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/03/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/03/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/03/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/03/5.webp',
            ],
          },
          {
            id:3,
            title:"大穗直发器",
            cover:this.$mediaURL+'/ESER_guide/case4/04.webp',
            type:"外观设计",
            info:'以简约的几何形态勾勒主体造型，截面由三角形过渡为六边形，丰富产品层次感，同时与正面造型相呼应，衬托产品 简约大方 ，科技时尚 的特点。\n' +
                '正面以亮亚材质对比与电镀件点缀，突出产品特征，凸显品质感与精致感。几何穿插的造型处理使产品更具识别性。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/04/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/04/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/04/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/04/4.webp',
            ],
          },
          {
            id:4,
            title:"轮马克吹风梳",
            cover:this.$mediaURL+'/ESER_guide/case4/05.webp',
            type:"外观设计",
            info:'采用简约时尚的戴森设计语言，干练的衔接型机身，搭配光洁的电镀件装饰，提高产品精致度。\n' +
                '梳齿采用粗细区分的排布，平衡摩擦力，手柄的偏置设计，更加符合梳子的人机使用。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/05/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/05/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/05/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/05/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/05/5.webp',
            ],
          },
          {
            id:5,
            title:"亚细亚卷发棒",
            cover:this.$mediaURL+'/ESER_guide/case4/06.webp',
            type:"外观设计",
            info:'小蛮腰握柄线条设计，手柄外形具有流线感。\n' +
                '手柄处材质内部采用电镀件，外表面做渐变透明处理，整体晶莹剔透。\n' +
                '顶部防烫手柄处增加装饰件做精致点缀。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/06/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/06/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/06/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/06/4.webp',
            ],
          },
          {
            id:6,
            title:"头部按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/07.webp',
            type:"外观设计",
            info:'外形采用曲面设计，贴合人体头部的曲线，分考虑人体工程学原则，确保按摩仪在使用时能够更好地适应用户的身体曲线和需求。整体呈现出圆润的外观，创造出友好、亲切的外观感受。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/07/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/07/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/07/3.webp',
            ],
          },
          {
            id:7,
            title:"吊坠按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/08.webp',
            type:"外观设计",
            info:'以吊坠为基础，使按摩仪可以佩戴在颈部，兼具装饰性和功能性。整体融合了方形和圆形元素，创造出独特的外观特点。采用挂脖式设计，用户可以轻松佩戴，自由调整按摩力度。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/08/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/08/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/08/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/08/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/08/5.webp',
            ],
          },
          {
            id:8,
            title:"膝盖按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/09.webp',
            type:"外观设计",
            info:'膝盖按摩仪注重圆润的外观设计，融入多层次按摩体验，旨在为用户提供舒适、细致的膝部按摩，同时注重外观美感和全面按摩效果。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/09/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/09/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/09/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/09/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/09/5.webp',
              this.$mediaURL+'/ESER_guide/case4/main/09/6.webp',
            ],
          },
          {
            id:9,
            title:"四叶草充电式颈部按摩枕",
            cover:this.$mediaURL+'/ESER_guide/case4/10.webp',
            type:"外观设计",
            info:'采用云朵的意象融入按摩枕的设计，嘭嘭的造型，给人云朵般柔软舒适的感觉，贴合产品属性。\n' +
                '前端磁吸扣的设计，防止侧向，不易滑出。整体造型简洁圆润，添以电镀件点缀，提升产品的层次感与价值感。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/10/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/10/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/10/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/10/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/10/5.webp',
            ],
          },
          {
            id:10,
            title:"智能眼部按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/11.webp',
            type:"外观设计",
            info:'理想的眼部按摩仪，具备按摩的基本功能外，更应该让用户拥有SPA般的放松体验，打造高颜值、易携带、集艺术感于一身的美学设计。\n' +
                '外观设计上采用渐削面的结合，富有曲线感及流动感，犹如一款时尚护目镜。将循环感与扭转感进行几何化处理，通过几何色块的结合，打造出异于竞品的产品外观，展现不断创新的品牌态度。\n' +
                '在传统的中心对称手法上，由两端斜切线条分割产品主面，剔除多余的设计，以线条的时尚与艺术感作为视觉调性，展现充满活力的品牌性格。\n' +
                '选取饱和度低的颜色，契合产品的同时，利用柔和的色彩传达产品质感，在每个细微处，让用户感受到无限放松，达成产品设计的使用初衷。\n' +
                '极简主义设计，除材料的使用，亦兼顾空间考虑。180°折叠便携，满足办公、睡前、出差等各种场景的使用，随时随地来场眼部SPA。\n' +
                '四种仿真揉压模式，气囊按摩眼眶及眼周穴位，38-42℃恒温热敷+循环按摩，10分钟定时关机。\n' +
                '圆点镂空的屏面设计，透气化+可视化，缓解全黑密闭的焦虑感，避免因视觉受阻带来的不便。\n' +
                '体量轻巧易拿取，蛋白皮亲肤内衬，深度贴合脸部曲线，内凹设计不压眼。\n' +
                '内置场景音乐，亦可连接蓝牙随心切换，体验沉浸式眼部按摩。\n' +
                '隐藏式Type-C充电口，大容量锂电池，2小时续航，随时呵护。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/11/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/11/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/11/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/11/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/11/5.webp',
              this.$mediaURL+'/ESER_guide/case4/main/11/6.webp',
            ],
          },
          {
            id:11,
            title:"腰部按摩仪",
            cover:this.$mediaURL+'/ESER_guide/case4/12.webp',
            type:"外观设计",
            info:'外形设计强调圆润和柔和感，以大跑道圆的形态为基础，赋予产品动感和流畅感，同时增加了视觉吸引力。采用对称的圆形功能区，使操作按钮和磁吸功能分布均衡，提升用户的操作便捷性。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/12/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/12/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/12/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/12/4.webp',
            ],
          },
          {
            id:12,
            title:"恩博坐灸仪",
            cover:this.$mediaURL+'/ESER_guide/case4/13.webp',
            type:"外观设计",
            info:'主要目标用户为女性群体，在侧面线条、背部艾灸孔和艾灸盒上运用蝴蝶元素。整体线条偏柔美，营造一种柔软、舒适感。\n' +
                '靠背内侧考虑人机体验，做倾斜设计，外侧面减小弧度做闭合设计，满足收纳需求。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/13/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/13/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/13/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/13/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/13/5.webp',
            ],
          },
          {
            id:13,
            title:"筋膜枪",
            cover:this.$mediaURL+'/ESER_guide/case4/14.webp',
            type:"外观设计",
            info:'运用具有力量感和符合视觉动力的折线和折面的变化，设计充满专业感，突显其强大功能和科技氛围。硅胶手柄设计独具握持感，为用户提供稳固舒适的握持体验，使操作更加轻松。运用流畅的线条，赋予产品动感和时尚感，同时凸显其现代化特征。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/14/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/14/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/14/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/14/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/14/5.webp',
            ],
          },
          {
            id:14,
            title:"传奇美微针导入仪",
            cover:this.$mediaURL+'/ESER_guide/case4/15.webp',
            type:"外观设计",
            info:'主体采用跑道圆截面的型材，打造差异化造型，确保在使用过程中的稳定性和舒适性。整体简约大气，侧面的纹理丰富产品质感和层次感。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/15/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/15/2.webp',
            ],
          },
          {
            id:15,
            title:"轮马克纳米注氧仪",
            cover:this.$mediaURL+'/ESER_guide/case4/16.webp',
            type:"外观设计",
            info:'设计灵感源自美人鱼鱼尾，顶面装饰件圆润光泽，在简约的风格下，又保有丰富的曲线美。\n' +
                '机身侧面既可以采用曲线纹理丰富层次，也能使简约整体的曲面提升质感。\n' +
                '丰富的配色令产品更加多姿多彩。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/16/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/16/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/16/3.webp',
            ],
          },
          {
            id:16,
            title:"眼雾仪",
            cover:this.$mediaURL+'/ESER_guide/case4/17.webp',
            type:"外观设计",
            info:'采用类似护目镜的时尚造型，正面设计了透明大视窗，用户可以随时看到外界景象，同时不影响眼部护理的进行。顶部设计科技感的贯穿式条灯，增加产品的科技感，为用户提供柔和的照明，提升使用体验。水箱设计在顶部注水口采用硅胶塞设计，防止漏水，提高使用的便捷性。设计简单易用的操作按钮，用户可以轻松控制雾化和灯光功能。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/17/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/17/2.webp',
            ],
          },
          {
            id:17,
            title:"再玩电动牙刷",
            cover:this.$mediaURL+'/ESER_guide/case4/18.webp',
            type:"外观设计",
            info:'以圆与跑道圆的结合作为突出特征。内凹圆作为灯光，以日暮色彩变化以映射产品使用时间。通过跑道圆的分割将按键与模式区域区分开，同时运用电镀件以增加产品的精致感。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/18/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/18/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/18/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/18/4.webp',
            ],
          },
          {
            id:18,
            title:"晨欣达壁挂式智能牙刷消毒架",
            cover:this.$mediaURL+'/ESER_guide/case4/19.webp',
            type:"外观设计",
            info:'产品造型取自“跑道圆”元素，由顶面与正面的两个跑道圆形成。亮哑面和电镀件增加了层次感与细节感。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/19/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/19/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/19/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/19/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/19/5.webp',
              this.$mediaURL+'/ESER_guide/case4/main/19/6.webp',
              this.$mediaURL+'/ESER_guide/case4/main/19/7.webp',
              this.$mediaURL+'/ESER_guide/case4/main/19/8.webp',
            ],
          },
          {
            id:19,
            title:"井井有序血压仪",
            cover:this.$mediaURL+'/ESER_guide/case4/20.webp',
            type:"外观设计",
            info:'该方案以圆角矩形为主要元素，外扩式的侧面设计，整体呈现下大上小的形态，形体更显稳重。\n' +
                '流瀑式的A面设计，既富有强烈的特征性，又使喇叭朝向用户，给用户声音更大的感觉。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/20/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/20/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/20/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/20/4.webp',
            ],
          },
          {
            id:20,
            title:"眼镜清洗盒",
            cover:this.$mediaURL+'/ESER_guide/case4/21.webp',
            type:"外观设计",
            info:'外观采用呈圆柱形状，既能容纳眼镜，又具有简约美感。设计轻巧便携，适合随身携带，随时保持眼镜清洁。内部技术采用超声波清洁技术，能够高效去除眼镜表面的污垢和细菌。操作简单，用户只需放入眼镜，启动清洗功能即可。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/21/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/21/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/21/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/21/4.webp',
              this.$mediaURL+'/ESER_guide/case4/main/21/5.webp',
            ],
          },
          {
            id:21,
            title:"固特小型家用超声波清洗机",
            cover:this.$mediaURL+'/ESER_guide/case4/22.webp',
            type:"外观设计",
            info:'以“鲸鱼”为意向，借用其轮廓曲线与纹理，融合应用于产品造型之中，顶盖电镀装饰件尽显产品的精致可爱。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/22/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/22/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/22/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/22/4.webp',
            ],
          },
          {
            id:22,
            title:"家瑞康压缩式雾化器",
            cover:this.$mediaURL+'/ESER_guide/case4/23.webp',
            type:"外观设计",
            info:'整体简约圆润一体，圆润的造型亲和儿童用户，体现产品儿童属性。设计细节形态上层次感丰富，侧面两边提起形式更适合力量小的儿童去移动。',
            main:[
              this.$mediaURL+'/ESER_guide/case4/main/23/1.webp',
              this.$mediaURL+'/ESER_guide/case4/main/23/2.webp',
              this.$mediaURL+'/ESER_guide/case4/main/23/3.webp',
              this.$mediaURL+'/ESER_guide/case4/main/23/4.webp',
            ],
          },
        ],
        [
          {
            id:0,
            title:"挂脖风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/01.webp',
            type:"外观设计",
            info:'设计灵感来源于年轻人潮流穿搭单品，从鞋子和手表中提取落差层次感作为设计参考，满足年轻用户的审美志趣，打造高颜值产品。\n' +
                '设计时依据人机工程学，加大接触面，分散产品重量，佩戴的舒适性是用户体验的重要因素。\n' +
                '隐藏式的出风口设计，保持造型完整性，双侧出风。在保证风力的情况下，最大程度降低噪音，也是该款产品核心竞争力的关键。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/01/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/01/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/01/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/01/4.webp',
            ],
          },
          {
            id:1,
            title:"桌面喷雾风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/02.webp',
            type:"外观设计",
            info:'以月牙为意象，设计了“月牙形”支架造型，形体简约。米白主色营造高级感的同时，进一步提升产品质感。\n' +
                '这款风扇特别设计了一个驱蚊模块，结构增加雾化喷嘴和储水盒零件——滴入几滴精油：白日凉风习习，香氛包围；夜间滴入驱蚊精油，凉风吹拂，又达到驱蚊效果。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/02/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/02/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/02/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/02/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/02/5.webp',
            ],
          },
          {
            id:2,
            title:"再玩婴儿车风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/03.webp',
            type:"外观设计",
            info:'以可爱的小熊为设计元素，旨在为婴儿提供清爽凉风的同时，营造一个温馨可爱的出行氛围。产品正面的按键采用卡通熊造型，背面的进风口设计成熊的眼睛，中间的螺丝孔则像熊的鼻子，将功能元素融入到设计中，增添了趣味性和亲和力。整体设计圆润柔和，为婴儿的出行增添了愉悦的体验。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/03/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/03/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/03/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/03/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/03/5.webp',
            ],
          },
          {
            id:3,
            title:"维特世嘉折叠小风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/04.webp',
            type:"外观设计",
            info:'设计灵感来源于露营灯。整体圆润简洁，外壳采用弧面设计，质感和手感兼具。\n' +
                '主打无线使用，强中柔三档风力设置，自然舒适，轻音低噪。\n' +
                '折叠后呈跑道造型，便于收纳；底部灯光搭配茶色半透明灯罩，露营氛围拉满。\n' +
                '可悬挂、落地，充分匹配帐篷内外使用场景。\n' +
                '降温、驱蚊、照明，一台风扇解决露营三大痛点。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/04/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/04/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/04/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/04/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/04/5.webp',
              this.$mediaURL+'/ESER_guide/case5/main/04/6.webp',
            ],
          },
          {
            id:4,
            title:"再玩户外风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/05.webp',
            type:"外观设计",
            info:'整体设计注重硬朗的线条和简洁的外观，使产品在户外环境中显得干练和精致。\n' +
                '在硬朗简洁的外观中融入独特的元素，弧线的正背面设计呼应，赋予产品流畅的外观。三脚架的固定方式带来强烈的独特性，为产品增添了独特性和差异感，同时确保了稳固性，适应不同户外环境。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/05/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/05/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/05/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/05/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/05/5.webp',
              this.$mediaURL+'/ESER_guide/case5/main/05/6.webp',
            ],
          },
          {
            id:5,
            title:"再玩折叠壁挂式风扇",
            cover:this.$mediaURL+'/ESER_guide/case5/06.webp',
            type:"外观设计",
            info:'采用硬朗的折线特征，中部装饰件和按键处皆采用八边形的元素，整体风格更具户外感。\n' +
                '上下对称的弧形曲线特征，加宽了中部轴的宽度，同时以对称的两条特征使其在视觉上显窄，显精致。\n' +
                '产品尾部设置可打开的硅胶带设计，既可作为提手，也可便于绑在帐篷/栏杆/树枝等位置，适用于多种户外场景。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/06/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/06/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/06/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/06/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/06/5.webp',
              this.$mediaURL+'/ESER_guide/case5/main/06/6.webp',
            ],
          },
          {
            id:6,
            title:"郭氏暖手宝",
            cover:this.$mediaURL+'/ESER_guide/case5/07.webp',
            type:"外观设计",
            info:'产品采用简洁、圆润的线条, 手握位置采用收腰设计，保证舒适的握感。\n' +
                '暖手宝将可爱的兔子形象与创意的太空舱设计相结合，采用了兔子的形象设计，萌趣的兔子头部呼应整体产品，采用太空舱形式的透明罩子，让用户可以清晰地看到里面的小兔子，同时也增加了科技感。按键设计成了一个小胡萝卜，不仅符合兔子的主题，还增加了趣味性，创造出一个既实用又充满趣味的产品。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/07/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/07/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/07/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/07/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/07/5.webp',
            ],
          },
          {
            id:7,
            title:"多功能暖手宝",
            cover:this.$mediaURL+'/ESER_guide/case5/08.webp',
            type:"外观设计",
            info:'迷你便携，三挡恒温调节，随时享受握在手心里的温暖。\n' +
                '正面和侧面设有软胶，可发光，可肆意揉捏一秒弹回，解压又好玩。\n' +
                '圆滚滚的肚子符合人体手握习惯，表面涂层细腻处理，亲肤手感更舒适。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/08/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/08/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/08/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/08/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/08/5.webp',
            ],
          },
          {
            id:8,
            title:"再玩暖腰带",
            cover:this.$mediaURL+'/ESER_guide/case5/09.webp',
            type:"外观设计",
            info:'暖腰带以温暖和舒适为核心，融合方形元素的简约美感。在外观设计中，偏方形的外轮廓为产品增添了独特性，同时将方形元素运用到透明件、按键、屏幕等部分，创造了一种统一感和和谐感。正面采用凹陷的面来营造面的变化，透明视窗内放置了一只陪伴的萌宠，为产品赋予了情感和温馨。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/09/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/09/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/09/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/09/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/09/5.webp',
            ],
          },
          {
            id:9,
            title:"暖杯垫",
            cover:this.$mediaURL+'/ESER_guide/case5/10.webp',
            type:"外观设计",
            info:'暖杯垫将复古的质感与现代科技相结合，以独特的设计元素为用户带来冬季的温暖和舒适。整体采用圆形设计，赋予产品柔和的外观，也为电镀装饰件的融入创造了合适的空间。通过圆形带小耳朵的电镀装饰件，在视觉上为产品增色，为整体设计带来更多层次感。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/10/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/10/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/10/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/10/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/10/5.webp',
            ],
          },
          {
            id:10,
            title:"浴室暖风机",
            cover:this.$mediaURL+'/ESER_guide/case5/11.webp',
            type:"外观设计",
            info:'',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/11/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/11/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/11/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/11/4.webp',
              this.$mediaURL+'/ESER_guide/case5/main/11/5.webp',
              this.$mediaURL+'/ESER_guide/case5/main/11/6.webp',
            ],
          },
          {
            id:11,
            title:"暖风机",
            cover:this.$mediaURL+'/ESER_guide/case5/12.webp',
            type:"外观设计",
            info:'采用立式设计，方便全屋移动，可以在需要时随意移动并放置于不同房间。家居设计适合大部分家庭环境，融入现代生活的美感。拥有高效的加热系统，能够迅速提供温暖的空气，配备智能温控技术，可根据环境温度调整取暖功率，节省能源的同时保持舒适。',
            main:[
              this.$mediaURL+'/ESER_guide/case5/main/12/1.webp',
              this.$mediaURL+'/ESER_guide/case5/main/12/2.webp',
              this.$mediaURL+'/ESER_guide/case5/main/12/3.webp',
              this.$mediaURL+'/ESER_guide/case5/main/12/4.webp',
            ],
          },


        ],
      ],
    }
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    goToPage(val){
      this.$router.push(val);
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";

    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    setFontSizeByBodyH(  h){
      return  "font-size:"+ this.htmlHeight/h + "px;";
    },
    getCatalogIndex(){
      var s = this.$route.params.title;
      this.case_detail=this.$route.params.case;
      for(var i=0;i<this.models.length;i++)
      {
        if( s==this.models[i].title_en.replace(/\s/g,"_") )
        {
          this.catalogSelect=i;
        }
      }
      this.caseShow=this.caseDetails[this.catalogSelect][this.case_detail];
    },
    goBack(){

      var   s = this.models[this.catalogSelect].title_en.replace(/\s/g,"_");
      var url= encodeURI('/services/'+ s  )  ;
      this.$router.push(url);
    },
  },
}
</script>

<style scoped>
.container_border_debug{
//border-radius: calc(100vh * 5 / 375);
  border: 0.5px solid rgba(255, 255, 255, 1);
  border-color: red;
  color: black;
}
.flex_row_center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_row_between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
