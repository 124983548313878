import Vuex from 'vuex'
const state = {
    locator: 0,
    catalogSelect:0,
    lastTime:0,
    case_detail_id:0,
}
const mutations = {
    set_locator_info (state, locator) {
        state.locator=locator;
        localStorage.setItem('locator', JSON.stringify(locator));

    },
    set_case_info(state,case_info){
        state.catalogSelect=case_info;
        localStorage.setItem('catalogSelect', JSON.stringify(case_info));
    },

    set_lastTime(state){
        state.lastTime=Date.now();
        localStorage.setItem('lastTime', JSON.stringify(state.lastTime));
    },
    set_case_detail_id(state,case_id){
        state.case_detail_id=case_id;
        localStorage.setItem('case_detail_id', JSON.stringify(case_id))
    }


}
const getters = {
}
export default new Vuex.Store({
    state,
    mutations,
    getters
})
