<template>
    <div   class="container_bgImg bg-img0" >
      <div   class="container_bgImg bg-img0"   :style="getStyleByBodyWd(1,1)"   >
        <el-row class="  main_center_contain" :style="getStyleByBodyWd(1.08,1.1)">
          <el-col :span="10" class=" index_left_container"  :style="getStyleByBodyWd(0,1.12)">
            <div>
              <div>
                <img :src="imgs.eser_cn" :style="getStyleByBodyWd(0,4.69)">
              </div>
              <div class="index_head_red_title" :style="setFontSizeByBodyH(11)"> 工业设计</div>
            </div>
            <div>
              <div :style="'margin-bottom:'+htmlHeight/12.7 +'px;'">
                <div  class="left_ServiceText" v-for="(t,index) in leftServiceText" :key="index" :style="setFontSizeByBodyH(24)">{{t}}</div>
              </div>
              <div style="display: flex" :style="getStyleByBodyWd(0,12.7)">
             <img :src="imgs.icon" :style="getStyleByBodyWd(0,12.7)">
                <div :style="getStyleByBodyWd(53,12.7)"    style="display:flex;align-items: center; justify-content: center">
                  <img  style=" opacity: 0" :src="imgs.star" :style="getStyleByBodyWd(0,36)">
                  <img :src="imgs.star" :style="getStyleByBodyWd(0,36)">
                </div>

              </div>
            </div>
          </el-col>
          <img :src="imgs.center" :style="getStyleByBodyWd(0,1.12) +'margin-left: -'+htmlHeight/6 +'px;'"  style="position: absolute; z-index: 10"  >
          <el-col  :span="14" class="  index_right_container"  :style="getStyleByBodyWd(0,1.12)">
            <!--  右上         -->
            <div>
              <div>
                <img :src="imgs.eser_en" :style="getStyleByBodyWd(0,4.69)">
              </div>
              <div style="text-align: end;" :style="'margin-top:'+htmlHeight/24 +'px;'">
                <div :style="setFontSizeByBodyH(32.75)">INDUSTRAL</div>
                <div :style="setFontSizeByBodyH(32.75)">DESIGN</div>
              </div>
            </div>
            <!--  右下         -->
            <div>
              <div :style="'margin-bottom:'+htmlHeight/12.7 +'px;'">
                <img :src="imgs.button" :style="getStyleByBodyWd(0,6) +'margin-top: -'+htmlHeight/10 +'px;'"  style="position: absolute; z-index: 10" class="home_button" @click="goToPage">
                <div  class="right_ServiceText" v-for="(t,index) in rightServiceText" :key="index" :style="setFontSizeByBodyH(24)">{{t}}</div>
              </div>

              <div style="display: flex;align-items: center;justify-content: space-between" :style="getStyleByBodyWd(0,20.76)">
                <img :src="imgs.arrow" :style="getStyleByBodyWd(0,24) + 'margin-right:'+htmlHeight/20 +'px;' "  >
                <div >
                  <div :style="setFontSizeByBodyH(41.5)"  class="copyright_text">COPYRIGHT@2023 以色工业设计手册  |  全部产品版权归易客创新有限公司所有</div>
                  <div :style="setFontSizeByBodyH(41.5)" class="copyright_text" >技术版权归易客创新（泉州）智能科技有限公司所有 |  闽ICP备19023234号-2</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
</template>

<script>
export default {
  name: "IndexPage",
  data() {
    return {
      locator:0,
      catalogSelect:0,
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      imgs:{
        center:this.$mediaURL+'/ESER_guide/page0/center0.gif',
        button:this.$mediaURL+'/ESER_guide/page0/button_r.gif',
        arrow: this.$mediaURL+'/ESER_guide/page0/arrow.webp',
        icon:this.$mediaURL+'/ESER_guide/page0/icon.webp',
        star:this.$mediaURL+'/ESER_guide/page0/star.webp',
        eser_cn:this.$mediaURL+'/ESER_guide/page0/eser_cn.webp',
        eser_en:this.$mediaURL+'/ESER_guide/page0/eser_en.webp',


  },
      leftServiceText:["创意外观设计","结构工程设计","电子方案设计","品牌全案设计",],
      rightServiceText:["INDUSTRIAL DESIGN","MECHANICAL DESIGN","ELECTRONIC","BRAND DESIGN",],

    }
  },
  mounted() {
    var timeout = 3600000;
    if(localStorage.getItem("lastTime")!=null && Date.now() -localStorage.getItem("lastTime")<timeout )
    {
      console.log("没超");
      if(localStorage.getItem("locator")==4)
      {
        this.locator=localStorage.getItem("locator");
        this.catalogSelect=localStorage.getItem("catalogSelect");
      }

    }else
    {
      localStorage.removeItem("locator");
      localStorage.removeItem("catalogSelect");
      this.$store.commit('set_lastTime');
    }


    this.init();

  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      // this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;

    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    setFontSizeByBodyH(  h){
        return  "font-size:"+ this.htmlHeight/h + "px;";
    },
    goToPage(){
      this.$router.push('/company');
    },
  },
}
</script>

<style scoped>
.exhibition_index{
  left: 0px;
  top: 0px;
  width:  100%;
  /*height: 375px;*/
  opacity: 1;
  background: rgba(0, 0, 0, 1);

}


* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;

}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

}

@media screen and (orientation: portrait) {
  /*竖屏样式*/
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}

</style>

<style lang="less" >
#app {
  /*文字居中*/
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;


}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;




}

@media screen and (orientation: portrait) {
  /*竖屏样式*/
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "思源黑体 Normal";

  src: url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/ai81Uk3UDG3h.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/FlDwLLUgK8xC.woff") format("woff");
  font-display: swap;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "思源宋体 Heavy";font-weight: 900;src: url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/YBIJHPMcxVtS.woff2") format("woff2"),
url("//at.alicdn.com/wf/webfont/GP1RyVf8SvFw/wsVi5q9yLzHr.woff") format("woff");
  font-display: swap;
}
.bg-img0 {
  background-size:100%;
  background: url("https://download.icheer.cn/app/ESER_guide/page0/background.webp") center no-repeat;
}
.main_center_contain{
  justify-content: space-around;
  align-items: center;
}
.index_left_container{
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
}
.index_head_red_title{
  color: red;
  font-family: "Microsoft YaHei";
  font-weight: 800;
  text-align: start;
}
.index_right_container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
}
.left_ServiceText{
  color: black;
  font-weight: bold;
  text-align: start;
}

.right_ServiceText{
  color: black;
  font-weight: bold;
  text-align: end;
}
.copyright_text{
  font-family: "思源黑体 Normal";
  font-weight:500;
  color: rgba(0, 0, 0, 1);
  text-align: right;
  vertical-align: top;
}
.home_button{
  opacity: 1;
  cursor: pointer; /*悬停变小手的属性*/
}

.container_bgImg
{
  background-size: 100%;
  background-repeat: no-repeat;
  background-position:center;
  align-items: center;
  justify-content: center;
  display: flex;
}


</style>
