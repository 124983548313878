
import {
    createRouter,
    createWebHashHistory
} from 'vue-router' //引入vue-router
import index from '../pages/index'//首页
import team from '../pages/team'
import company from "@/pages/company";
import services from "@/pages/services"
import caseDetail from "@/pages/caseDetail";
import waterFall from "@/pages/waterFall";
//首页
// import login from '../views/login.vue'//登陆页


const routes = [
    {
    path: '',
    redirect: "index"
   },
    {
    path: '/',
    redirect: "index" //redirect:重定向，意思是当路径是"/"时重新跳转到home
   },
    // {
    //     path: '/login',//路径名
    //     name: 'login',//别名，可以随意取名的
    //     component: login,
    //     meta: {
    //         title: '登录'
    //     }
    // },
    {
        path: '/index',
        name: 'index',
        component: index
    },
    {
        path: '/team',//动态匹配路径，后面会有使用举例
        name: 'team',
        component: team
    },
    {
        path: '/company',
        name: 'company',
        component: company
    },


    {
        path: '/services',
        name: 'services',
        component: services,
        meta: {
            flag: true,
        },
        beforeEnter: (to, from)=> {
            // console.log('positon',from.path.toString().indexOf("/services/"));
            if(from.path.toString().includes("/services/",0))
            {
                to.meta.flag=false;
            }

        },
    },
    {
        path: '/services/:title',
        name: 'waterfall',
        component: waterFall,

    },
    {
        path: '/caseDetail/:title:/:case',
        name: 'caseDetail',
        component: caseDetail,
        params: { caseShow: '' }

    },

];

const router = createRouter({
    //mode: 'hash',三张模式：hash|history|abstract，默认hash
    history: createWebHashHistory(),
    routes
})
// 注册一个全局后置守卫
// router.afterEach((to,from) => {
//   console.log(to.path);
//   console.log(from.path);
// })


export default router;
