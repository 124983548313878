<template>
  <!--  &lt;!&ndash; 案例介绍    &ndash;&gt;-->
  <div    >
<!--    &lt;!&ndash; 动画介绍 &ndash;&gt;-->
    <div v-if="play_flag" style="justify-content: end;align-items: end;display: flex;" :style="getStyleByBodyWd(1,1)">
      <div :style="'margin-bottom:'+htmlHeight/12+'px;'+'margin-right:'+htmlHeight/16+'px;'"   style="position: absolute; z-index: 10;cursor: pointer;" @click="play_flag=false"   >
        <img :src="imgs.button" :style="getStyleByBodyWd(0,6)">
      </div>
      <div  style="display: flex;justify-content: center;align-items: center;"  :style="getStyleByBodyWd(1,1)" >
        <video
            x5-video-player-type="h5"
            x5-video-orientation="landscape"
            x5-video-player-fullscreen="true"
            webkit-playsinline   playsinline
            width="100%"
            muted="muted"  preload="auto"  loop="loop"  autoplay   :style="getStyleByBodyWd(1,1)"  style="width: 100%; height:100%; object-fit:fill">
          <source :src="imgs.video" type="video/mp4"  >
        </video>
      </div>

<!--      <img :src="imgs.ward" :style="getStyleByBodyWd(1,1)">-->

    </div>
    <!-- 目录页   -->
    <div v-else   class="bg-img  " :style="getStyleByBodyWd(1,1)" >
      <el-row    :style="getStyleByBodyWd(1,1.1)" >
        <el-col :span="10"    :pull="3">
          <div  :style="'margin-left:'+htmlHeight/15+'px;'">
            <div><img :src="imgs.title" :style="getStyleByBodyWd(0,20.37) +'margin-left:'+htmlHeight/15+'px;'" ></div>
            <div ><img :src="imgs.ice" :style="getStyleByBodyWd(0,1.78) +'margin-top:'+htmlHeight/16+'px;'" ></div>
            <div><img :src="imgs.button_back" :style="getStyleByBodyWd(0,6) +'margin-left:'+htmlHeight/12+'px;'"  style=" cursor: pointer;"  @click="goToPage('/index')"></div>
          </div>


        </el-col>
        <el-col :span="14"  >
          <div :style="'margin-right:'+htmlWidth/27+'px;'" >
            <div>
              <div>
                <el-row style="display: flex;justify-content: space-between;align-items: center;">
                  <div><img :src="imgs.number" :style="getStyleByBodyWd(0,8.78)"></div><div><img :src="imgs.icon" :style="getStyleByBodyWd(0,12.7)"></div>
                </el-row>
                <el-row style="display: flex;justify-content: space-between;align-items: center; text-align: justify">
                  <div   :span="24" :style="setFontSizeByBodyH(8.4) +'letter-spacing: '+htmlWidth/620+'px;'"  style="color: black;font-family: 黑体;font-weight: bolder;text-align: justify;" >EXCELLENT</div>
                  <div   :span="24" :style="setFontSizeByBodyH(8.4) +'letter-spacing: '+htmlWidth/620+'px;'"  style="color: black;font-family: 黑体;font-weight: 900;text-align: justify;">IDEA</div>
                </el-row>
              </div>
              <div v-for="(m,index) in models" :key="index"  >
                <el-row class="catalog_list   "  :style="'margin-top:'+htmlHeight/36+'px;'"  style=" cursor: pointer; font-width: 500;text-align: start"  @click="caseCatalogChange(index,m.title_en)"  >
                  <el-col :span="8" :style="  index==5?'color: rgba(108, 110, 157, 1);'+setFontSizeByBodyH(30):''+setFontSizeByBodyH(30)" >{{m.title}}</el-col>
                  <el-col :span="8" :style="index==5?'color: rgba(108, 110, 157, 1);'+setFontSizeByBodyH(38):''+setFontSizeByBodyH(38)" >{{m.title_en}}</el-col>
                  <el-col :span="8" style="display:flex;justify-content:flex-end" ><img :src="m.arrow" :style="getStyleByBodyWd(0,29.2) +'margin-right:'+htmlWidth/58+'px;'" ></el-col>
                </el-row>
                <div class="catalog_list" :style="'margin-top:'+htmlHeight/36+'px;'"><img :src="m.line" :style="getStyleByBodyWd(1.84,0)"></div>
              </div>
            </div>

          </div>

        </el-col>
      </el-row>
    </div>


  </div>


</template>

<script>


export default {
  name: "servicesPage",

  data() {
    return {
      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      play_flag:false,
      imgs:{
        button:this.$mediaURL+'/ESER_guide/page0/button_r.gif',
        background:this.$mediaURL+'/ESER_guide/catalog/background.webp',
        icon:this.$mediaURL+'/ESER_guide/catalog/icon.webp',
        ice:this.$mediaURL+'/ESER_guide/catalog/ice.webp',
        title:this.$mediaURL+'/ESER_guide/catalog/eser_title.webp',
        button_back:this.$mediaURL+'/ESER_guide/catalog/back.gif',
        number:this.$mediaURL+'/ESER_guide/catalog/5000.webp',
        video:this.$mediaURL+'/ESER_guide/catalog/ward.mp4',
        ward:this.$mediaURL+'/ESER_guide/catalog/ward.gif',
      },

      models:[
        {
         title:"家电家居",
          title_en:"Household Appliances",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow1.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line1.webp',
        },
        {
          title:"3C数码",
          title_en:"3C Digital Accessories",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow2.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line2.webp',
        },
        {
          title:"童品优选",
          title_en:"Children's Products",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow3.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line3.webp',
        },
        {
          title:"个护健康",
          title_en:"Personal Healthcare",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow4.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line4.webp',
        },
        {
          title:"两季产品",
          title_en:"Seasonal Products",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow5.webp',
          line:this.$mediaURL+'/ESER_guide/catalog/line5.webp',
        },
        {
          title:"品牌全案设计",
          title_en:"Brand Design",
          arrow:this.$mediaURL+'/ESER_guide/catalog/arrow6.webp',
          line:"",
        },
      ],
    }
  },
  created() {
    console.log(this.$route.meta.flag,this.play_flag);
    this.play_flag=this.$route.meta.flag;
  },
  mounted() {

    // var timeout = 3600000;
    // if(localStorage.getItem("lastTime")!=null && Date.now() -localStorage.getItem("lastTime")<timeout )
    // {
    //   console.log("没超");
    //
    // }else
    // {
    //   localStorage.removeItem("locator");
    //   localStorage.removeItem("catalogSelect");
    //   this.$store.commit('set_lastTime');
    // }

    console.log(this.$route.meta.flag,this.play_flag);
    this.init();
    this.rePlayVideo();

  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    goToPage(val){
      this.$router.push(val);
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";

    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    setFontSizeByBodyH(  h){
      return  "font-size:"+ this.htmlHeight/h + "px;";
    },
    rePlayVideo(){
      // this.play_flag=true;
      setTimeout(() =>{
        this.play_flag=false;
      }, 16000);
    },
    caseCatalogChange(index,  titles){

      if(index==5)
      {
        var  src ="https://exhibition.eiker.com.cn/";
        window.open(src);
      }else
      {
        var   s = titles.replace(/\s/g,"_");
        var url= encodeURI('/services/'+ s  )  ;
        this.$router.push(url);
      }
      // this.locator=4;
      // this.catalogSelect=index;
      // this.locator=4;
      // this.catalogSelect=index;
      // this.$store.commit('set_locator_info',this.locator);
      // this.$store.commit('set_case_info',this.catalogSelect);
      // this.case_index=0;
      // this.videoCaseIndex=0;
      // this.isCaseTransition=true;
      // setTimeout(() => {
      //   this.isCaseTransition=false;
      // }, 400);
      // this.waterfallList=[];
      // this.imgPreloading();
      // this.refareshBtn();
      // this.waterfall_key++;
      // location.reload();
    },
  },

}
</script>

<style scoped>
.bg-img {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position:center;
  align-items: center;
  justify-content: center;
  display: flex;
  background-image: url("https://download.icheer.cn/app/ESER_guide/catalog/background.webp")  ;
}
.catalog_left_container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
}
.catalog_list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.container_border_debug{
//border-radius: calc(100vh * 5 / 375);
  border: 0.5px solid rgba(255, 255, 255, 1);
  border-color: red;
  color: black;
}
.background_contain{
  background-size:100%;
  background: url("https://download.icheer.cn/app/ESER_guide/page0/background.webp") center no-repeat;
}
</style>
