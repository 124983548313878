<template>
  <!--  &lt;!&ndash;  团队成员  &ndash;&gt;-->
  <div   style="  background: rgba(255, 255, 255, 1);  display: flex;justify-content: center;align-items: center"   :style="getStyleByBodyWd(1,1)"    >
    <el-row :style="getStyleByBodyWd(1.1,1.15)"   >
      <el-col :span="12" class="  left_container" :style="getStyleByBodyWd(0,1.1)" >
        <div >
          <div><img :src="imgs.title" :style="getStyleByBodyWd(5.8,0)"></div>
          <div class="title_song_style" :style="setFontSizeByBodyH(12)">企业介绍</div>
          <div :style="'margin-top:'+htmlHeight/18+'px;'"  >
            <div class="title_red_cn " :style="setFontSizeByBodyH(20) +'letter-spacing: '+htmlWidth/188+'px;'" style="text-align: justify">更懂电子产品的工业设计专家</div>
            <div class="title_red_en "  :style="setFontSizeByBodyH(60) +'letter-spacing: '+htmlWidth/1800+'px;'"  style="text-transform: uppercase ;text-align: justify">industrial design experts who know more about electronic products</div>
          </div>

        </div>
        <div>
          <el-scrollbar :style="getStyleByBodyWd(2.23,2)"  >
            <div :style="setFontSizeByBodyH(41.5)" style="font-weight: 500">
              以色工业设计是易客创新旗下的核心旗舰企业，拥有ID/MD/平面/视频等专业
              设计师百余名，致力于为广大中小企业提供专业的电子产品工业设计服务。
            </div>
            <div :style="setFontSizeByBodyH(41.5) +'margin-top:'+htmlHeight/30+'px;' " style="font-weight: 500;text-align: justify" >
              公司依托易客创新强大的智能电子产品软硬件工程师团队，为客户提供从外观创意设计/结构工程设计到电子方案设计的一站式电子产品原创设计服务，以及产品市场调研/结构堆叠规划/结构优化评审/电子方案评估/跟模及量产技术支持/专利评估报告/平面和视频宣传制作等各种配套增值服务。</div>
            <div :style="setFontSizeByBodyH(60) +'margin-top:'+htmlHeight/30+'px;'" style="font-weight: 500;text-align: justify">
              ESER Industrial Design is the core flagship enterprise of EIKER, with more than 100 professional ID/MD/graphic/video designers, committed to the majority of small and medium-sized enterprises to provide professional electronic products industrial design services.
              Relying on EIKER's powerful intelligent electronic product hardware and software engineer team, the company provides customers with one-stop electronic product original design services from  creative appearance design/structural engineering design to electronic solution design. As well as product market research/structural stack planning/structural optimization review/electronic solution evaluation/mold and mass production technical support/patent evaluation report/graphic and video publicity production and other supporting value-added services.</div>
          </el-scrollbar>
        </div>
      </el-col>
      <el-col :span="12" class="  right_container">
        <div ><img :src="imgs.icon"  :style="getStyleByBodyWd(0,12.7)"></div>
        <div :style="getStyleByBodyWd(2.5,1.3) +'margin-top'+htmlHeight/20+'px;'+'background:url('+imgs.background+')center no-repeat'" style="background-size: 100% 100%;color: black; ">
          <div style="text-align: end;" :style="setFontSizeByBodyH(38) +'margin-top:'+htmlHeight/20+'px;'" >
            <div v-for="t in leftServiceText" :key="t" :style="'margin-top:'+htmlHeight/135+'px;margin-bottom'+htmlHeight/135+'px;'">{{t}}</div>
          </div>
          <div :style="setFontSizeByBodyH(9.6)+ 'margin-left:-'+htmlHeight/30+'px;'"  style="text-align: start;font-weight: bold"  >ESER</div>
          <div style="text-align: start;" :style="setFontSizeByBodyH(38) +'margin-top:'+htmlHeight/20+'px;'+ 'margin-left:-'+htmlHeight/30+'px;'" >
            <div v-for="t in rightServiceText" :key="t" :style="'margin-top:'+htmlHeight/135+'px;margin-bottom'+htmlHeight/135+'px;'">{{t}}</div>
          </div>
        </div>
        <div  :style="getStyleByBodyWd(2.3,1.4)" style="position: absolute; z-index: 10"> <img :src="imgs.ice"   :style="getStyleByBodyWd(2.3,1.41) +'margin-top'+htmlHeight/20+'px;'"></div>
        <div  :style="getStyleByBodyWd(10.6,6) +'margin-top:-'+htmlHeight/8+'px;'"><img :src="imgs.button" style=" cursor: pointer;" :style="getStyleByBodyWd(10.6,6)" @click="goToPage('/team')"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "companyPage",
  data(){
    return{

      htmlWidth:document.getElementsByTagName('html')[0].clientWidth,
      htmlHeight:document.getElementsByTagName('html')[0].clientHeight,
      imgs:{
        title:this.$mediaURL+'/ESER_guide/catalog/eser_title.webp',
        button:this.$mediaURL+'/ESER_guide/page0/button_r.gif',
        background:this.$mediaURL+'/ESER_guide/page1/background_right.webp',
        icon:this.$mediaURL+'/ESER_guide/catalog/icon.webp',
        ice:this.$mediaURL+'/ESER_guide/ice.gif',
      },
      leftServiceText:["创意外观设计","结构工程设计","电子方案设计","品牌全案设计",],
      rightServiceText:["INDUSTRIAL DESIGN","MECHANICAL DESIGN","ELECTRONIC","BRAND DESIGN",],
    }
  },
  mounted() {
    this.init();
    this.isHomeTransition=true;
    setTimeout(() => {
      this.isHomeTransition=false;
    }, 1000);
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      this.windowTurn=html.clientWidth<html.clientHeight?true:false;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
      this.htmlHeight=min;
      this.htmlWidth=max;
      this.waterfallImgWidth=this.htmlWidth/4.89;
      this.waterfallImgRight=this.htmlWidth/66;
      this.waterfallImgBottom=this.htmlWidth/66;
      this.mainStyle="height:"+min + "px;"+"width:"+max + "px";
      // for(let n=0;n<this.waterfallList.length;n++)
      // {
      //   var imgData =this.waterfallList[n];
      // }
    },
    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreenHandle);
    },
    getStyleByBodyWd(  w,h){
      if(w==0)
        return  "height:"+ this.htmlHeight/h + "px;";
      else if(h==0)
        return "width:"+this.htmlWidth/w+ "px;";
      else
        return  "height:"+ this.htmlHeight/h + "px;"+"width:"+this.htmlWidth/w+ "px;";
    },
    goToPage(val){
      this.$router.push(val);
    },
    setFontSizeByBodyH(  h){
      return  "font-size:"+ this.htmlHeight/h + "px;";
    },
  },
}
</script>

<style scoped>

.left_container{
  color: black;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title_song_style{
  font-family: "思源宋体 Heavy";
  font-weight: 900;
}
.title_red_cn{
  font-weight: 900;
  letter-spacing: 3px;
  color: rgba(255, 44, 45, 1);
  text-align: left;
  vertical-align: top;

}
.right_container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.title_red_en{

  font-weight: 900;
  letter-spacing: 0px;
  color: rgba(255, 44, 45, 0.5);
  text-align: left;
  vertical-align: top;


}

</style>
