import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store/store'
import App from './App.vue'
import router from './router'



// import Vue3TouchEvents from "vue3-touch-events";
// 引入全局工具类
import untils from '@/utils/common';
import VueLazyLoad from 'vue-lazyload'
// Vue.use(VueLazyLoad,{
//     error:'./static/error.png',
//     loading:'./static/loading.png'
// })



// const compression = require('compression');
const app = createApp(App)
// app.use(compression());
app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(VueLazyLoad,{
    // error:'./static/error.png',
    // loading:"https://download.icheer.cn/app/media/loading.webp",
})
// app.use(Vue3TouchEvents)
app.config.globalProperties.$mediaURL = 'https://download.icheer.cn/app'
app.config.globalProperties.$untils = untils
app.mount('#app')
